/*
 * Colors
 */
$colors: (
  white: #FFFFFF,
  black: #000000,
  blue: #1F56CC,
  light-yellow: #FFEFD6,
  yellow: #F9C107,
  light-gray: #F1F2F7,
  gray: #E1E2E7,
  dgray: #D8D8D8,
  ddgray: #96989B,
  dark-gray: #4D5360,
  light-blue: #8497A0,
  green: #4AB62E,
  red: #DC3545,
  transparent: rgba(255, 255, 255, 0),
  white-op-10: rgba(255, 255, 255, 0.1)
);

// Color map helper function
@function color($color) {
  @return map-get($colors, $color);
}

@each $name, $hex in $colors {
  // Color
  .c-#{$name} {
    color: $hex;
  }

  // Background Color
  .bgc-#{$name} {
    background-color: $hex;
  }

  // Border Color
  .bc-#{$name} {
    border-color: $hex;
  }
}

.i-gs {
  filter: grayscale(1);
}
