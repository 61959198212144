/*
 * Plugins
 *
 * Override styling added by plugins
 */

// Swiper
.swiper-slide {
  &:not(.swiper-slide-active) {
    > * { // sass-lint:disable-line no-universal-selectors
      opacity: 0.5;
    }
  }
}

.swiper-pagination.swiper-pagination-bullets {
  .swiper-pagination-bullet {
    height: rem-calc(10);
    width: rem-calc(10);
    margin: rem-calc(0 2);
    background-color: color(light-blue);
  }

  .swiper-pagination-bullet-active {
    background-color: color(blue);
  }
}
