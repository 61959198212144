.privacy-statement,
.terms-and-conditions {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 22px;

    &.font-weight-light {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 14px;

      &.list-item {
        margin-bottom: 5px;
      }
    }
  }

  li {
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 22px;
  }

  ul {
    padding-left: 30px;
    margin-bottom: 10px;
    list-style-type: disc;
    list-style-position: outside;
  }

  ol {
    padding-left: 30px;
    margin-bottom: 0;
    list-style-type: decimal;
    list-style-position: outside;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: outside;
    margin-left: 15px;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: outside;
    margin-left: 15px;
  }
}
