/**
 * Variables
 */

// Spacer - paddings and offsets are based on this
$spacer: rem-calc(15);

// Lists for for loops
$shortdirs: t, b, l, r;
$longdirs: top, bottom, left, right;

$percentages: (
  10,
  20,
  25,
  30,
  33.33333333,
  40,
  49,
  50,
  60,
  66.66666666,
  70,
  75,
  80,
  90,
  100
);

$floats: (
  0.1,
  0.2,
  0.3,
  0.4,
  0.5,
  0.6,
  0.7,
  0.8,
  0.9,
);

// Bootstrap overwrites

$theme-colors: (
    "primary":    #1955CD,
    "light":      #F1F2F7,
    "marine":     #E8F0FF,
    "dark":       #101929,
);
