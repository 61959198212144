.faq {
  .faq-accordion {
    a {
      font-size: rem-calc(18);
      position: relative;
      cursor: pointer;

      &::before {
        position: absolute;
        left: -1.5em;
        top: -0;
        width: 1em;
        height: 1.3em;
        content: "";
        background-image: url("/dist/images/symbols/faq/plus.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        padding-left: 0.2em;
        padding-right: 0.2em;
        pointer-events: none;
      }

      &[aria-expanded="true"] {
        font-weight: bold;

        &::before {
          background-image: url("/dist/images/symbols/faq/min.svg");
        }
      }
    }
  }

  .hidden {
    display: none;
  }
}