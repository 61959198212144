/*
 * Border radius
 */

// sass-lint:disable class-name-format

@media #{$tablet} {
  .T-bb-n {
    border-bottom: none;
  }

  .T-br-10 {
    border-radius: rem-calc(10);
  }

  // Tablet hover state
  .T-h-bd-b:hover {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    margin-bottom: -1px;
  }

  .T-h-bd-b-scroll:hover {
    border-bottom: 1px dashed rgba(56, 85, 99, 0.5);
  }

  .T-b-white {
    border: 1px solid color(white);
  }

  .T-b-light-gray {
    border: 1px solid color(light-gray);
  }

  .T-br-l-10 {
    border-top-left-radius: rem-calc(10);
    border-bottom-left-radius: rem-calc(10);
  }

  .T-br-r-10 {
    border-top-right-radius: rem-calc(10);
    border-bottom-right-radius: rem-calc(10);
  }

  .T-br-tl-10 {
    border-top-left-radius: rem-calc(10);
  }

  .T-br-tr-10 {
    border-top-right-radius: rem-calc(10);
  }

  .T-br-t-10 {
    border-top-left-radius: rem-calc(10);
    border-top-right-radius: rem-calc(10);
  }

  .T-br-b-10 {
    border-bottom-left-radius: rem-calc(10);
    border-bottom-right-radius: rem-calc(10);
  }
}
