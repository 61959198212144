.et-editable-image {
  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), none;
    border: rem-calc(1) solid #F9C107;
    border-radius: rem-calc(4);
    margin: rem-calc(-1);

    .et-edit-text-button {
      display: block;
    }
  }
}
