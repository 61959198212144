.et-hidden,
.et-edit-mode-notification.et-flyout-open .flyout.open,
.et-edit-mode-notification:not(.et-flyout-open) .flyout.close,
.et-editable-text-old {
  display: none;
}

body.disable-hover {
  pointer-events: none;
}

.et-edit-modal .button,
.et-edit-mode-notification .button {
  display: block;
  padding: rem-calc(15 30);
  border-radius: rem-calc(4);
  background-color: #567482;
  cursor: pointer;
  color:  #FFFFFF;
}

.et-edit-mode-notification {
  background-color: whitesmoke;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 2;
  transform: translate(calc(-100% + 45px), -50%);
  border-radius: rem-calc(0 4 4 0);
  box-shadow: rem-calc(0 2 4) rgba(34, 34, 34, 0.3);
  padding: rem-calc(15 45 15 15);
  transition: transform 0.5s ease-out;
  font-size: rem-calc(22);
  font-weight: bold;

  &.et-flyout-open {
    transform: translate(0, -50%);
  }

  .button.publish {
    background-color: #F9C107;
  }

  .button:not(.publish) {
    margin-top: rem-calc(15);
  }

  .flyout {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: rem-calc(10 5);
    cursor: pointer;

    svg {
      width: rem-calc(30);
      height: rem-calc(30);
    }
  }

  .et-edit-mode-label {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: rem-calc(10 7);
    writing-mode: vertical-lr;
  }
}

.et-editable-text {
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), none;
    border: rem-calc(1) solid grey;
    border-radius: rem-calc(4);
    margin: rem-calc(-1);

    .et-edit-text-button {
      display: block;
    }
  }
}

.et-edit-text-button {
  display: none;
  width: auto;
  padding: rem-calc(5);
  position: absolute;
  top: rem-calc(-1);
  right: rem-calc(-1);
  line-height: 0;
  border-radius: rem-calc(4);
  background-color: #F9C107;
  color: #FFFFFF;
  cursor: pointer;

  svg.fa-magic {
    width: rem-calc(20);
    height: rem-calc(20);
  }
}

.et-edit-modal {
  .h2 {
    font-size: rem-calc(22);
    font-weight: bold;
    text-align: center;
  }

  .et-text-area {
    resize: vertical;
    width: 100%;
    margin: rem-calc(15 0);
    border: 1px solid lightgrey;
    border-radius: rem-calc(4);
    padding: rem-calc(10);
    box-sizing: border-box;
  }

  .et-button-container {
    display: flex;
    justify-content: space-around;
  }

  .button {
    border-radius: rem-calc(4);
    max-width: 40%;
  }

  .revert {
    background-color: #567482;
  }
}
