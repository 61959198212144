/*
 * Utilities
 */

// Variables
$reveal-animation-duration: 6.5s;
$filter-color: #698E9C;

// Vertically centers item
.cent-v {
  top: 50%;
  transform: translateY(-50%);
}

.active-tab-trigger {
  border-color: color(yellow) !important; // sass-lint:disable-line no-important
  font-weight: bold;
}

.shadow-overlay-right {
  &::after {
    content: ' ';
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-image: linear-gradient(to right, color(transparent), color(white));
  }
}

.shadow-overlay-sides {
  position: relative;

  &::before,
  &::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2rem;
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to left, color(transparent), color(gray));
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to right, color(transparent), color(gray));
  }
}

.revealable {
  animation: grow-from-center ease $reveal-animation-duration;

  &.last {
    animation: grow-from-center-last ease $reveal-animation-duration;
  }

  @keyframes grow-from-center {
    from {
      transform: scale(0.3, 0.3);
    }

    5% {
      transform: scale(1.1, 1.1);
    }

    10% {
      transform: scale(1, 1);
    }

    92% {
      transform: scale(1, 1);
    }

    95% {
      transform: scale(1.1, 1.1);
    }

    to {
      transform: scale(0.3, 0.3);
    }
  }

  @keyframes grow-from-center-last {
    from {
      transform: scale(0.3, 0.3);
    }

    5% {
      transform: scale(1.1, 1.1);
    }

    10% {
      transform: scale(1, 1);
    }
  }
}

.overflow-scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

.image-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $filter-color;
  opacity: 0.5;
}
