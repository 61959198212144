/*
 * Typography
 */

// sass-lint:disable class-name-format variable-name-format

// Variables
$T-fontsizes: (
  16,
  18,
  20,
  22,
  26,
  32,
  48,
  72
);

$T-line-heights: (
  17,
  26,
  28,
  39,
  48,
  68
);

@media #{$tablet} {

  // Text
  @each $size in $T-fontsizes {
    .T-fs-#{$size} {
      font-size: rem-calc($size);
    }
  }

  // Line height
  @each $height in $T-line-heights {
    .T-lh-#{$height} {
      line-height: rem-calc($height);
    }
  }

  .T-fw-normal {
    font-weight: normal;
  }

  // Text align
  .T-ta-s {
    text-align: start;
  }

  .T-ta-e {
    text-align: end;
  }
}

// Text decoration
.td-lt {
  text-decoration: line-through;
}

.td-u {
  text-decoration: underline;
}

.td-u-h:hover {
  text-decoration: underline;
}

