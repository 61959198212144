.yquery-modal-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), none;
}

.yquery-modal {
  display: none;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background-color: white;
  padding: 1.875rem;
  min-width: 30%;
  border: solid 1px #666666;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

  .close-yquery-modal {
    position: absolute;
    right: 0.75rem;
    top: 0;
    padding: 0.625rem;
    color: #AAAAAA;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    cursor: pointer;
  }
}
