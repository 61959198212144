/*
 * Display
 */

// sass-lint:disable class-name-format variable-name-format

// Variables
$T-heights: (
  110,
  210,
  250,
  310,
  442,
  500,
  502
);

$T-widths: (
  170,
  250,
  275,
  500,
  550,
  700,
  730,
  820
);

$T-width-percentages: (
  30,
  40,
  48,
  50,
  70,
  100
);

$T-max-widths: (
  360,
  463,
  500,
  600,
  630,
  720,
  760,
  830,
  850
);


@media #{$tablet} {
  // Display
  .T-d-fl {
    display: flex;
  }

  .T-d-bl {
    display: block;
  }

  .T-d-ibl {
    display: inline-block;
  }

  .T-d-no {
    display: none;
  }

  // Sizes
  .T-w-full {
    width: 100%;
  }

  @each $height in $T-heights {
    .T-h-#{$height} {
      height: rem-calc($height);
    }
  }

  @each $width in $T-max-widths {
    .T-mw-#{$width} {
      max-width: rem-calc($width);
    }
  }

  .T-miw-33p {
    min-width: calc(100% / 3);
  }

  .T-mw-25p {
    max-width: 25%;
  }

  .T-mw-33p {
    max-width: calc(100% / 3);
  }

  .T-mw-540 {
    max-width: rem-calc(540);
  }

  .T-mw-800 {
    max-width: rem-calc(800);
  }

  @each $width in $T-width-percentages {
    .T-w-#{$width}p {
      width: percentage($width / 100);
    }
  }

  .T-w-33p {
    width: calc(100% / 3);
  }

  .T-w-66p {
    width: calc((100% / 3) * 2);
  }

  .T-w-a {
    width: auto;
  }

  @each $width in $T-widths {
    .T-w-#{$width} {
      width: rem-calc($width);
    }
  }

  .T-mh-300 {
    max-height: rem-calc(300);
  }

  .T-mih-300 {
    min-height: rem-calc(300);
  }

  .T-mw-700 {
    max-width: rem-calc(700);
  }

  .T-of-auto {
    overflow: auto;
  }

  // Opacity
  .T-op-100 {
    opacity: 1;
  }

  // Shadows
  .T-ts {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }

  .T-bs-no {
    box-shadow: none;
  }

  .T-bs {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }
}
