$navbar-height: 66px;

nav.navbar-mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  button.navbar-toggler {
    padding: 0;
    outline: 0;
  }

  .navbar-collapse {
    position: absolute;
    top: $navbar-height;
    left: 0;
    z-index: 1;
    height: calc(100vh - 60px);
    width: 100%;
  }

  .animated-icon {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      background: #000000;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 10px;
      }

      &:nth-child(4) {
        top: 20px;
      }
    }

    &.open {
      span {
        &:nth-child(1) {
          top: 11px;
          width: 0;
          left: 50%;
        }

        &:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        &:nth-child(4) {
          top: 11px;
          width: 0;
          left: 50%;
        }
      }
    }
  }
}

nav.navbar-desktop {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;

    a.nav-link {
      &:hover {
        font-weight: bold;
      }
    }
  }
}
