/*
 * Utilities
 */

// sass-lint:disable class-name-format no-ids

// Variables
$animation-duration: 45s;
$translate-distance: 100px;

@media #{$tablet} {
  .shadow-overlay-sides {
    &::before,
    &::after {
      width: 3rem;
    }
  }

  // Hover parent
  .T-hp:hover {
    &.T-hp-mb-n15s {
      margin-bottom: $spacer * -15;
    }

    &.T-hp-z-1 {
      z-index: 1;
    }

    &.T-hp-bs {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    }

    // Hover children
    .T-hc-d-bl {
      display: block;
    }

    .T-hc-mb-1s {
      margin-bottom: $spacer;
    }
  }

  // Header
  .T-page-header {
    &:not(.T-scrolled) {
      box-shadow: none;
      flex-direction: column;
      position: absolute;
      transition: background-color 0.5s ease-out;

      .menu-content {
        a {
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        }
      }

      .logo-common {
        display: none;
      }
    }

    &.T-scrolled {
      background-color: color(white);
      transition: none;

      .menu-content {
        a {
          color: color(blue);

          &:hover {
            border-bottom: 1px dashed rgba(56, 85, 99, 0.5);
          }
        }
      }

      .logo-common {
        img {
          height: rem-calc(60);
        }
      }

      .logo-tablet {
        display: none;
      }
    }
  }

  .T-movement-animation {
    .up-down {
      animation: MoveUpDown $animation-duration linear infinite;
    }

    .down-up {
      animation: MoveDownUp $animation-duration linear infinite;
    }

    @keyframes MoveUpDown {
      0% {
        transform: translateY(0%);
      }

      50% {
        transform: translateY(-$translate-distance);
      }

      100% {
        transform: translateY(0%);
      }
    }

    @keyframes MoveDownUp {
      0% {
        transform: translateY(0%);
      }

      50% {
        transform: translateY($translate-distance);
      }

      100% {
        transform: translateY(0%);
      }
    }
  }
}
