/*
 * Misc
 *
 * Miscellaneous classes
 */

// Cursor
.curs-p {
  cursor: pointer;
}

.curs-g {
  cursor: grab;
}

// Checkbox input with images
input[type='checkbox'] {
  position: relative;
  margin: 0;
  width: rem-calc(20);
  height: rem-calc(20);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: rem-calc(20);
    height: rem-calc(20);

    background-image: url("/dist/images/symbols/checkbox-unchecked.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  &:checked::after {
    background-image: url("/dist/images/symbols/checkbox-checked.svg");
  }
}

// Disabled button
button[disabled] {
  opacity: 0.5;
}

// Remove scrollbar
.rm-sb {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */  // sass-lint:disable-line no-misspelled-properties
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.rm-sb::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

// Blue Outline when selected buttons
button.selected {
  outline: #1447AB auto 5px;
}
