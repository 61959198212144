.application {
  box-sizing: border-box;
  height: 762px;
  width: 1444px;
  max-height: 762px;
  max-width: 1444px;

  .content {
    height: 760px;
    max-height: 760px;
  }
}