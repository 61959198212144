/*
 * Spacing
 */

// sass-lint:disable class-name-format variable-name-format

// Variables
$T-margin-count: 6;
$T-padding-count: 7;

@media #{$tablet} {

  // Margin
  @for $count from 0 through $T-margin-count {
    @for $dir from 1 through 4 {
      .T-m#{nth($shortdirs, $dir)}-#{$count}s {
        margin-#{nth($longdirs, $dir)}: $spacer * $count;
      }
    }

    .T-m-#{$count}s {
      margin: $spacer * $count;
    }

    .T-mx-#{$count}s {
      margin-left: $spacer * $count;
      margin-right: $spacer * $count;
    }

    .T-my-#{$count}s {
      margin-top: $spacer * $count;
      margin-bottom: $spacer * $count;
    }
  }

  .T-mb-n6s {
    margin-bottom: $spacer * -6;
  }

  // Padding
  @for $count from 0 through $T-padding-count {
    @for $dir from 1 through 4 {
      .T-p#{nth($shortdirs, $dir)}-#{$count}s {
        padding-#{nth($longdirs, $dir)}: $spacer * $count;
      }
    }

    .T-p-#{$count}s {
      padding: $spacer * $count;
    }

    .T-px-#{$count}s {
      padding-left: $spacer * $count;
      padding-right: $spacer * $count;
    }

    .T-py-#{$count}s {
      padding-top: $spacer * $count;
      padding-bottom: $spacer * $count;
    }
  }

  .T-pb-235 {
    padding-bottom: rem-calc(235);
  }

  .T-pt-11s {
    padding-top: $spacer * 11;
  }

  .T-pt-14s {
    padding-top: $spacer * 14;
  }
}
