/*
 * Display
 */

// Variables
$widths: (
  8,
  12,
  16,
  20,
  22,
  24,
  30,
  36,
  40,
  80,
  100,
  130,
  230,
  250,
  255
);

$heights: (
  8,
  12,
  16,
  20,
  22,
  24,
  30,
  36,
  40,
  80,
  100,
  110,
  130,
  275,
  290,
);

$opacity-percentages: (
  10,
  20,
  40,
  50,
  100
);

// General
.d-fl {
  display: flex;
}

.d-no {
  display: none;
}

.d-bl {
  display: block;
}

.d-ibl {
  display: inline-block;
}

.d-ifl {
  display: inline-flex;
}

// Widths
@each $width in $widths {
  .w-#{$width} {
    width: rem-calc($width);
  }
}

//Example: .w-33p => width: 33.33333333%;
@each $percentage in $percentages {
  .w-#{floor($percentage)}p {
    width: $percentage * 1%;
  }
}

.w-100vw {
  width: 100vw;
}

// Heights
@each $height in $heights {
  .h-#{$height} {
    height: rem-calc($height);
  }
}

@each $percentage in $percentages {
  .h-#{floor($percentage)}p {
    height: $percentage * 1%;
  }
}

.h-100vh {
  height: 100vh;
}

// Overflow
.of-hidden {
  overflow: hidden;
}

.of-x-hidden {
  overflow-x: hidden;
}

.of-y-hidden {
  overflow-y: hidden;
}

.of-scr {
  overflow: scroll;
}

.of-x-scr {
  overflow-x: scroll;
}

.of-y-scr {
  overflow-y: scroll;
}

.of-y-auto {
  overflow-y: auto;
}

.ow-bw {
  overflow-wrap: break-word;
}

// Opacity
@each $percentage in $opacity-percentages {
  .op-#{$percentage} {
    opacity: $percentage / 100;
  }
}

.h-op-100:hover {
  opacity: 1;
}

.ph-op-35::placeholder {
  opacity: 0.35;
}

// Shadow
.bs {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

// Background
.bp-c {
  background-position: center;
}

.bs-c {
  background-size: cover;
}

.br-n {
  background-repeat: no-repeat;
}
