/*
 * Typography
 */

// Variables
$fontsizes: (
  9,
  12,
  14,
  15,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30
);

$line-heights: (
  0,
  13,
  18,
  20,
  21,
  24,
  27,
  30,
  34,
  40
);

@font-face {
  font-family: 'SweetSansProBold';
  src: url('/fonts/SweetSansPro/SweetSansProBold/SweetSansProBold.otf') format('otf');
  src: url('/fonts/SweetSansPro/SweetSansProBold/SweetSansProBold.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'SweetSansProHeavy';
  src: url('/fonts/SweetSansPro/SweetSansProHeavy/SweetSansProHeavy.otf') format('otf');
  src: url('/fonts/SweetSansPro/SweetSansProHeavy/SweetSansProHeavy.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'SweetSansProMedium';
  src: url('/fonts/SweetSansPro/SweetSansProMedium/SweetSansProMedium.otf') format('otf');
  src: url('/fonts/SweetSansPro/SweetSansProMedium/SweetSansProMedium.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'SweetSansProRegular';
  src: url('/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.otf') format('otf');
  src: url('/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.eot');
  src: url('/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.woff2') format('woff2'),
  url('/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.woff') format('woff'),
  url('/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.ttf')  format('truetype'),
  url('/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.svg#svgFontName') format('svg');
  font-style: normal;
  font-weight: normal;
}

.ff-SweetSansPro {
  font-family: "SweetSansProRegular", sans-serif;
  font-size: rem-calc(16);
  color: #333F58;
  line-height: rem-calc(26);

  h1 {
    font-family: "SweetSansProHeavy", sans-serif;
    font-size: rem-calc(40);
    line-height: rem-calc(50);
    font-weight: bold;
  }

  h2 {
    font-family: "SweetSansProBold", sans-serif;
    font-size: rem-calc(36);
    line-height: rem-calc(50);
    font-weight: bold;
  }

  h3 {
    font-family: "SweetSansProBold", sans-serif;
    font-size: rem-calc(24);
    line-height: rem-calc(24);
    font-weight: bold;
  }

  h4 {
    font-family: "SweetSansProMedium", sans-serif;
    font-size: rem-calc(20);
    line-height: rem-calc(24);
    font-weight: bold;
  }

  h5 {
    font-family: "SweetSansProMedium", sans-serif;
    font-size: rem-calc(18);
    font-weight: 500;
  }

  body,
  p,
  span,
  legend,
  input,
  select,
  textarea {
    font-family: "SweetSansProRegular", sans-serif;
    font-size: rem-calc(16);
    font-weight: 300;
  }

  .font-medium {
    font-family: "SweetSansProMedium", sans-serif;
    font-size: rem-calc(20);
    font-weight: 500;
  }
}

@font-face {
  font-family: "SofiaProBold";
  src: url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.eot"); /* IE9*/
  src: url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.woff2") format("woff2"),
  url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.woff") format("woff"),
  url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.ttf") format("truetype"),
  url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.svg#SofiaProLight") format("svg");
}

@font-face {
  font-family: "SofiaProRegular";
  src: url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.eot"); /* IE9*/
  src: url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.woff2") format("woff2"),
  url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.woff") format("woff"),
  url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.ttf") format("truetype"),
  url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.svg#SofiaProLight") format("svg");
}

@font-face {
  font-family: "SofiaProLight";
  src: url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.eot"); /* IE9*/
  src: url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.eot?#iefix") format("embedded-opentype"),
  url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.woff2") format("woff2"),
  url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.woff") format("woff"),
  url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.ttf") format("truetype"),
  url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.svg#SofiaProLight") format("svg");
}

.ff-SofiaPro {
  font-family: "SofiaProLight", sans-serif;
  font-size: rem-calc(16);
  line-height: rem-calc(22);
  color: #171717;

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  legend,
  span,
  input,
  select,
  textarea {
    font-weight: 400;
    font-size: 16px;
  }

  legend,
  span,
  input,
  textarea {
    line-height: 22px;
  }

  h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 19px 0;
  }

  h2,
  h3 {
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
  }

  h4 {
    opacity: 50%;
    margin: 10px 0;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 10px;
  }

  legend {
    color: #161616;
    margin: 0 0 9px;
  }

  input,
  textarea {
    color: #2A2C32;
  }

  @each $size in $fontsizes {
    .fs-#{$size} {
      font-size: rem-calc($size);
    }
  }
}

  //Font weight
.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-bold {
  font-weight: 700;
}

// Font sizes
// For setting the rem base
.fs-rem-base {
  font-size: 10px;
}

@each $size in $fontsizes {
  .fs-#{$size} {
    font-size: rem-calc($size);
  }
}

// Font style
.fst-italic {
  font-style: italic;
}

// Line height
@each $height in $line-heights {
  .lh-#{$height} {
    line-height: rem-calc($height);
  }
}

// Letter spacing
.ls-half {
  letter-spacing: 0.5px;
}

.ls-norm {
  letter-spacing: normal;
}

.ls-wide {
  letter-spacing: 1.5px;
}

// Text align
.ta-s {
  text-align: start;
}

.ta-c {
  text-align: center;
}

.ta-e {
  text-align: end;
}

// Text decoration
.td-lt {
  text-decoration: line-through;
}

.td-no {
  text-decoration: none;
}

.td-u {
  text-decoration: underline;
}

.td-u-h:hover {
  text-decoration: underline;
}
