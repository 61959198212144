/*
 * Colors
 */

// sass-lint:disable class-name-format

@media #{$tablet} {
  @each $name, $hex in $colors {
    // Color
    .T-c-#{$name} {
      color: $hex;
    }

    // Background Color
    .T-bgc-#{$name} {
      background-color: $hex;
    }
  }
}
