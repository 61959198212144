/*
 * Positioning
 */

// sass-lint:disable class-name-format variable-name-format

// Variables
$T-order-count: 4;
$T-spacing-count: 4;

@media #{$tablet} {
  // Position
  .T-pos-rel {
    position: relative;
  }

  .T-pos-abs {
    position: absolute;
  }

  .T-pos-sti {
    position: sticky;
  }

  // Flex
  .T-fl-col {
    flex-direction: column;
  }

  .T-fl-row {
    flex-direction: row;
  }

  .T-ai-c {
    align-items: center;
  }

  .T-ai-s {
    align-items: stretch;
  }

  .T-ai-st {
    align-items: start;
  }

  .T-jc-sb {
    justify-content: space-between;
  }

  .T-jc-fs {
    justify-content: flex-start;
  }

  .T-jc-fe {
    justify-content: flex-end;
  }

  .T-jc-c {
    justify-content: center;
  }

  .T-fw-w {
    flex-wrap: wrap;
  }

  // Order
  @for $order from 1 through $T-order-count {
    .T-ord-#{$order} {
      order: $order;
    }
  }

  /* Top/Right/Bottom/Left
 *
 * Example:
 * .T-t-1 => top: 15px;
 * .T-l-4 => left: 60px;
 */
  @for $dir from 1 through 4 {
    @for $count from 0 through $T-spacing-count {
      .T-#{nth($shortdirs, $dir)}-#{$count}s {
        #{nth($longdirs, $dir)}: $spacer * $count;
      }
    }

    .T-#{nth($shortdirs, $dir)}-a {
      #{nth($longdirs, $dir)}: auto;
    }
  }

  .T-r-n2s {
    right: -$spacer * 2;
  }

  .T-t-120 {
    top: rem-calc(120);
  }

  .T-tr-y-n6s {
    transform: translateY(-($spacer * 6));
  }

  .T-tr-y-n40 {
    transform: translateY(rem-calc(-40));
  }
}
