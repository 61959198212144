/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
input, textarea, button, select {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/*
 Apply a natural box layout model to all elements, but allowing components to change
 https://www.paulirish.com/2012/box-sizing-border-box-ftw/
 */
html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit; }

input, textarea, select {
  resize: none; }
  input:focus, textarea:focus, select:focus {
    outline: none; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none; }

strong, b {
  font-weight: bold; }

/**
 * Variables
 */
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1955CD;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #F1F2F7;
  --dark: #101929;
  --marine: #E8F0FF;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*, *::before, *::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.46875rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #1955CD;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #113989;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.46875rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 0.9375rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.46875rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 0.9375rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bfcff1; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #87a7e5; }

.table-hover .table-primary:hover {
  background-color: #aabfec; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #aabfec; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfbfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f8f8fb; }

.table-hover .table-light:hover {
  background-color: #eaeaf5; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eaeaf5; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bcbfc3; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #838790; }

.table-hover .table-dark:hover {
  background-color: #afb2b7; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #afb2b7; }

.table-marine,
.table-marine > th,
.table-marine > td {
  background-color: #f9fbff; }

.table-marine th,
.table-marine td,
.table-marine thead th,
.table-marine tbody + tbody {
  border-color: #f3f7ff; }

.table-hover .table-marine:hover {
  background-color: #e0eaff; }
  .table-hover .table-marine:hover > td,
  .table-hover .table-marine:hover > th {
    background-color: #e0eaff; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #779fee;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(25, 85, 205, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(25, 85, 205, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #1955CD;
  border-color: #1955CD; }
  .btn-primary:hover {
    color: #fff;
    background-color: #1547ab;
    border-color: #1342a0; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #1547ab;
    border-color: #1342a0;
    box-shadow: 0 0 0 0.2rem rgba(60, 111, 213, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #1955CD;
    border-color: #1955CD; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1342a0;
    border-color: #123d94; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(60, 111, 213, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #F1F2F7;
  border-color: #F1F2F7; }
  .btn-light:hover {
    color: #212529;
    background-color: #d9dbe9;
    border-color: #d1d4e4; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #d9dbe9;
    border-color: #d1d4e4;
    box-shadow: 0 0 0 0.2rem rgba(210, 211, 216, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #F1F2F7;
    border-color: #F1F2F7; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d1d4e4;
    border-color: #c8cce0; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(210, 211, 216, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #101929;
  border-color: #101929; }
  .btn-dark:hover {
    color: #fff;
    background-color: #05080d;
    border-color: #020304; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #05080d;
    border-color: #020304;
    box-shadow: 0 0 0 0.2rem rgba(52, 60, 73, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #101929;
    border-color: #101929; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #020304;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 60, 73, 0.5); }

.btn-marine {
  color: #212529;
  background-color: #E8F0FF;
  border-color: #E8F0FF; }
  .btn-marine:hover {
    color: #212529;
    background-color: #c2d7ff;
    border-color: #b5cfff; }
  .btn-marine:focus, .btn-marine.focus {
    color: #212529;
    background-color: #c2d7ff;
    border-color: #b5cfff;
    box-shadow: 0 0 0 0.2rem rgba(202, 210, 223, 0.5); }
  .btn-marine.disabled, .btn-marine:disabled {
    color: #212529;
    background-color: #E8F0FF;
    border-color: #E8F0FF; }
  .btn-marine:not(:disabled):not(.disabled):active, .btn-marine:not(:disabled):not(.disabled).active,
  .show > .btn-marine.dropdown-toggle {
    color: #212529;
    background-color: #b5cfff;
    border-color: #a8c6ff; }
    .btn-marine:not(:disabled):not(.disabled):active:focus, .btn-marine:not(:disabled):not(.disabled).active:focus,
    .show > .btn-marine.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(202, 210, 223, 0.5); }

.btn-outline-primary {
  color: #1955CD;
  border-color: #1955CD; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #1955CD;
    border-color: #1955CD; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 85, 205, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #1955CD;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #1955CD;
    border-color: #1955CD; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(25, 85, 205, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #F1F2F7;
  border-color: #F1F2F7; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #F1F2F7;
    border-color: #F1F2F7; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 242, 247, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #F1F2F7;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #F1F2F7;
    border-color: #F1F2F7; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 242, 247, 0.5); }

.btn-outline-dark {
  color: #101929;
  border-color: #101929; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #101929;
    border-color: #101929; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(16, 25, 41, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #101929;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #101929;
    border-color: #101929; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(16, 25, 41, 0.5); }

.btn-outline-marine {
  color: #E8F0FF;
  border-color: #E8F0FF; }
  .btn-outline-marine:hover {
    color: #212529;
    background-color: #E8F0FF;
    border-color: #E8F0FF; }
  .btn-outline-marine:focus, .btn-outline-marine.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 240, 255, 0.5); }
  .btn-outline-marine.disabled, .btn-outline-marine:disabled {
    color: #E8F0FF;
    background-color: transparent; }
  .btn-outline-marine:not(:disabled):not(.disabled):active, .btn-outline-marine:not(:disabled):not(.disabled).active,
  .show > .btn-outline-marine.dropdown-toggle {
    color: #212529;
    background-color: #E8F0FF;
    border-color: #E8F0FF; }
    .btn-outline-marine:not(:disabled):not(.disabled):active:focus, .btn-outline-marine:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-marine.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 240, 255, 0.5); }

.btn-link {
  font-weight: 400;
  color: #1955CD;
  text-decoration: none; }
  .btn-link:hover {
    color: #113989;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.46875rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #1955CD; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #1955CD;
    background-color: #1955CD; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(25, 85, 205, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #779fee; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #a5bff4;
    border-color: #a5bff4; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1955CD;
  background-color: #1955CD; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(25, 85, 205, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(25, 85, 205, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(25, 85, 205, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(25, 85, 205, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #779fee;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(25, 85, 205, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #779fee;
    box-shadow: 0 0 0 0.2rem rgba(25, 85, 205, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(25, 85, 205, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(25, 85, 205, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(25, 85, 205, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #1955CD;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #a5bff4; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #1955CD;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #a5bff4; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #1955CD;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #a5bff4; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1955CD; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.46875rem 0.9375rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0.9375rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1955CD;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #113989;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(25, 85, 205, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1955CD;
  border-color: #1955CD; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #1955CD; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #1342a0; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(25, 85, 205, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #F1F2F7; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #d1d4e4; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(241, 242, 247, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #101929; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #020304; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(16, 25, 41, 0.5); }

.badge-marine {
  color: #212529;
  background-color: #E8F0FF; }
  a.badge-marine:hover, a.badge-marine:focus {
    color: #212529;
    background-color: #b5cfff; }
  a.badge-marine:focus, a.badge-marine.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(232, 240, 255, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #0d2c6b;
  background-color: #d1ddf5;
  border-color: #bfcff1; }
  .alert-primary hr {
    border-top-color: #aabfec; }
  .alert-primary .alert-link {
    color: #07193e; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #7d7e80;
  background-color: #fcfcfd;
  border-color: #fbfbfd; }
  .alert-light hr {
    border-top-color: #eaeaf5; }
  .alert-light .alert-link {
    color: #646566; }

.alert-dark {
  color: #080d15;
  background-color: #cfd1d4;
  border-color: #bcbfc3; }
  .alert-dark hr {
    border-top-color: #afb2b7; }
  .alert-dark .alert-link {
    color: black; }

.alert-marine {
  color: #797d85;
  background-color: #fafcff;
  border-color: #f9fbff; }
  .alert-marine hr {
    border-top-color: #e0eaff; }
  .alert-marine .alert-link {
    color: #61646a; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1955CD;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #1955CD;
    border-color: #1955CD; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #0d2c6b;
  background-color: #bfcff1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #0d2c6b;
    background-color: #aabfec; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #0d2c6b;
    border-color: #0d2c6b; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #7d7e80;
  background-color: #fbfbfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7d7e80;
    background-color: #eaeaf5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7d7e80;
    border-color: #7d7e80; }

.list-group-item-dark {
  color: #080d15;
  background-color: #bcbfc3; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #080d15;
    background-color: #afb2b7; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #080d15;
    border-color: #080d15; }

.list-group-item-marine {
  color: #797d85;
  background-color: #f9fbff; }
  .list-group-item-marine.list-group-item-action:hover, .list-group-item-marine.list-group-item-action:focus {
    color: #797d85;
    background-color: #e0eaff; }
  .list-group-item-marine.list-group-item-action.active {
    color: #fff;
    background-color: #797d85;
    border-color: #797d85; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #1955CD !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1342a0 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #F1F2F7 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d1d4e4 !important; }

.bg-dark {
  background-color: #101929 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #020304 !important; }

.bg-marine {
  background-color: #E8F0FF !important; }

a.bg-marine:hover, a.bg-marine:focus,
button.bg-marine:hover,
button.bg-marine:focus {
  background-color: #b5cfff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #1955CD !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #F1F2F7 !important; }

.border-dark {
  border-color: #101929 !important; }

.border-marine {
  border-color: #E8F0FF !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.23438rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.23438rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.23438rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.23438rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.23438rem !important; }

.m-2 {
  margin: 0.46875rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.46875rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.46875rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.46875rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.46875rem !important; }

.m-3 {
  margin: 0.9375rem !important; }

.mt-3,
.my-3 {
  margin-top: 0.9375rem !important; }

.mr-3,
.mx-3 {
  margin-right: 0.9375rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 0.9375rem !important; }

.ml-3,
.mx-3 {
  margin-left: 0.9375rem !important; }

.m-4 {
  margin: 1.40625rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.40625rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.40625rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.40625rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.40625rem !important; }

.m-5 {
  margin: 2.8125rem !important; }

.mt-5,
.my-5 {
  margin-top: 2.8125rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2.8125rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2.8125rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2.8125rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.23438rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.23438rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.23438rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.23438rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.23438rem !important; }

.p-2 {
  padding: 0.46875rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.46875rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.46875rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.46875rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.46875rem !important; }

.p-3 {
  padding: 0.9375rem !important; }

.pt-3,
.py-3 {
  padding-top: 0.9375rem !important; }

.pr-3,
.px-3 {
  padding-right: 0.9375rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 0.9375rem !important; }

.pl-3,
.px-3 {
  padding-left: 0.9375rem !important; }

.p-4 {
  padding: 1.40625rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.40625rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.40625rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.40625rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.40625rem !important; }

.p-5 {
  padding: 2.8125rem !important; }

.pt-5,
.py-5 {
  padding-top: 2.8125rem !important; }

.pr-5,
.px-5 {
  padding-right: 2.8125rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2.8125rem !important; }

.pl-5,
.px-5 {
  padding-left: 2.8125rem !important; }

.m-n1 {
  margin: -0.23438rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.23438rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.23438rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.23438rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.23438rem !important; }

.m-n2 {
  margin: -0.46875rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.46875rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.46875rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.46875rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.46875rem !important; }

.m-n3 {
  margin: -0.9375rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -0.9375rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -0.9375rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -0.9375rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -0.9375rem !important; }

.m-n4 {
  margin: -1.40625rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.40625rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.40625rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.40625rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.40625rem !important; }

.m-n5 {
  margin: -2.8125rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -2.8125rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -2.8125rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -2.8125rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -2.8125rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.23438rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.23438rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.23438rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.23438rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.23438rem !important; }
  .m-sm-2 {
    margin: 0.46875rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.46875rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.46875rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.46875rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.46875rem !important; }
  .m-sm-3 {
    margin: 0.9375rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.9375rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.9375rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.9375rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.9375rem !important; }
  .m-sm-4 {
    margin: 1.40625rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.40625rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.40625rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.40625rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.40625rem !important; }
  .m-sm-5 {
    margin: 2.8125rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.8125rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.8125rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.8125rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.23438rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.23438rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.23438rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.23438rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.23438rem !important; }
  .p-sm-2 {
    padding: 0.46875rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.46875rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.46875rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.46875rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.46875rem !important; }
  .p-sm-3 {
    padding: 0.9375rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.9375rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.9375rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.9375rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.9375rem !important; }
  .p-sm-4 {
    padding: 1.40625rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.40625rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.40625rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.40625rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.40625rem !important; }
  .p-sm-5 {
    padding: 2.8125rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.8125rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.8125rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.8125rem !important; }
  .m-sm-n1 {
    margin: -0.23438rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.23438rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.23438rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.23438rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.23438rem !important; }
  .m-sm-n2 {
    margin: -0.46875rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.46875rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.46875rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.46875rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.46875rem !important; }
  .m-sm-n3 {
    margin: -0.9375rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.9375rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.9375rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.9375rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.9375rem !important; }
  .m-sm-n4 {
    margin: -1.40625rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.40625rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.40625rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.40625rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.40625rem !important; }
  .m-sm-n5 {
    margin: -2.8125rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2.8125rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2.8125rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2.8125rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2.8125rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.23438rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.23438rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.23438rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.23438rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.23438rem !important; }
  .m-md-2 {
    margin: 0.46875rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.46875rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.46875rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.46875rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.46875rem !important; }
  .m-md-3 {
    margin: 0.9375rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.9375rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.9375rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.9375rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.9375rem !important; }
  .m-md-4 {
    margin: 1.40625rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.40625rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.40625rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.40625rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.40625rem !important; }
  .m-md-5 {
    margin: 2.8125rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.8125rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.8125rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.8125rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.23438rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.23438rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.23438rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.23438rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.23438rem !important; }
  .p-md-2 {
    padding: 0.46875rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.46875rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.46875rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.46875rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.46875rem !important; }
  .p-md-3 {
    padding: 0.9375rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.9375rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.9375rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.9375rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.9375rem !important; }
  .p-md-4 {
    padding: 1.40625rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.40625rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.40625rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.40625rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.40625rem !important; }
  .p-md-5 {
    padding: 2.8125rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.8125rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.8125rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.8125rem !important; }
  .m-md-n1 {
    margin: -0.23438rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.23438rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.23438rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.23438rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.23438rem !important; }
  .m-md-n2 {
    margin: -0.46875rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.46875rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.46875rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.46875rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.46875rem !important; }
  .m-md-n3 {
    margin: -0.9375rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.9375rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.9375rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.9375rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.9375rem !important; }
  .m-md-n4 {
    margin: -1.40625rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.40625rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.40625rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.40625rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.40625rem !important; }
  .m-md-n5 {
    margin: -2.8125rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2.8125rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2.8125rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2.8125rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2.8125rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.23438rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.23438rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.23438rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.23438rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.23438rem !important; }
  .m-lg-2 {
    margin: 0.46875rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.46875rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.46875rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.46875rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.46875rem !important; }
  .m-lg-3 {
    margin: 0.9375rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.9375rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.9375rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.9375rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.9375rem !important; }
  .m-lg-4 {
    margin: 1.40625rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.40625rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.40625rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.40625rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.40625rem !important; }
  .m-lg-5 {
    margin: 2.8125rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.8125rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.8125rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.8125rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.23438rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.23438rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.23438rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.23438rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.23438rem !important; }
  .p-lg-2 {
    padding: 0.46875rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.46875rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.46875rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.46875rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.46875rem !important; }
  .p-lg-3 {
    padding: 0.9375rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.9375rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.9375rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.9375rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.9375rem !important; }
  .p-lg-4 {
    padding: 1.40625rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.40625rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.40625rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.40625rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.40625rem !important; }
  .p-lg-5 {
    padding: 2.8125rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.8125rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.8125rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.8125rem !important; }
  .m-lg-n1 {
    margin: -0.23438rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.23438rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.23438rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.23438rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.23438rem !important; }
  .m-lg-n2 {
    margin: -0.46875rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.46875rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.46875rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.46875rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.46875rem !important; }
  .m-lg-n3 {
    margin: -0.9375rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.9375rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.9375rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.9375rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.9375rem !important; }
  .m-lg-n4 {
    margin: -1.40625rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.40625rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.40625rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.40625rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.40625rem !important; }
  .m-lg-n5 {
    margin: -2.8125rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2.8125rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2.8125rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2.8125rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2.8125rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.23438rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.23438rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.23438rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.23438rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.23438rem !important; }
  .m-xl-2 {
    margin: 0.46875rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.46875rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.46875rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.46875rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.46875rem !important; }
  .m-xl-3 {
    margin: 0.9375rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.9375rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.9375rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.9375rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.9375rem !important; }
  .m-xl-4 {
    margin: 1.40625rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.40625rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.40625rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.40625rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.40625rem !important; }
  .m-xl-5 {
    margin: 2.8125rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.8125rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.8125rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.8125rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.8125rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.23438rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.23438rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.23438rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.23438rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.23438rem !important; }
  .p-xl-2 {
    padding: 0.46875rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.46875rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.46875rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.46875rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.46875rem !important; }
  .p-xl-3 {
    padding: 0.9375rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.9375rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.9375rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.9375rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.9375rem !important; }
  .p-xl-4 {
    padding: 1.40625rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.40625rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.40625rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.40625rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.40625rem !important; }
  .p-xl-5 {
    padding: 2.8125rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.8125rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.8125rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.8125rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.8125rem !important; }
  .m-xl-n1 {
    margin: -0.23438rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.23438rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.23438rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.23438rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.23438rem !important; }
  .m-xl-n2 {
    margin: -0.46875rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.46875rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.46875rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.46875rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.46875rem !important; }
  .m-xl-n3 {
    margin: -0.9375rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.9375rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.9375rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.9375rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.9375rem !important; }
  .m-xl-n4 {
    margin: -1.40625rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.40625rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.40625rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.40625rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.40625rem !important; }
  .m-xl-n5 {
    margin: -2.8125rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2.8125rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2.8125rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2.8125rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2.8125rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #1955CD !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #113989 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #F1F2F7 !important; }

a.text-light:hover, a.text-light:focus {
  color: #c0c5db !important; }

.text-dark {
  color: #101929 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-marine {
  color: #E8F0FF !important; }

a.text-marine:hover, a.text-marine:focus {
  color: #9cbeff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *, *::before, *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*
 * Display
 */
.d-fl {
  display: flex; }

.d-no {
  display: none; }

.d-bl {
  display: block; }

.d-ibl {
  display: inline-block; }

.d-ifl {
  display: inline-flex; }

.w-8 {
  width: 0.5rem; }

.w-12 {
  width: 0.75rem; }

.w-16 {
  width: 1rem; }

.w-20 {
  width: 1.25rem; }

.w-22 {
  width: 1.375rem; }

.w-24 {
  width: 1.5rem; }

.w-30 {
  width: 1.875rem; }

.w-36 {
  width: 2.25rem; }

.w-40 {
  width: 2.5rem; }

.w-80 {
  width: 5rem; }

.w-100 {
  width: 6.25rem; }

.w-130 {
  width: 8.125rem; }

.w-230 {
  width: 14.375rem; }

.w-250 {
  width: 15.625rem; }

.w-255 {
  width: 15.9375rem; }

.w-10p {
  width: 10%; }

.w-20p {
  width: 20%; }

.w-25p {
  width: 25%; }

.w-30p {
  width: 30%; }

.w-33p {
  width: 33.33333%; }

.w-40p {
  width: 40%; }

.w-49p {
  width: 49%; }

.w-50p {
  width: 50%; }

.w-60p {
  width: 60%; }

.w-66p {
  width: 66.66667%; }

.w-70p {
  width: 70%; }

.w-75p {
  width: 75%; }

.w-80p {
  width: 80%; }

.w-90p {
  width: 90%; }

.w-100p {
  width: 100%; }

.w-100vw {
  width: 100vw; }

.h-8 {
  height: 0.5rem; }

.h-12 {
  height: 0.75rem; }

.h-16 {
  height: 1rem; }

.h-20 {
  height: 1.25rem; }

.h-22 {
  height: 1.375rem; }

.h-24 {
  height: 1.5rem; }

.h-30 {
  height: 1.875rem; }

.h-36 {
  height: 2.25rem; }

.h-40 {
  height: 2.5rem; }

.h-80 {
  height: 5rem; }

.h-100 {
  height: 6.25rem; }

.h-110 {
  height: 6.875rem; }

.h-130 {
  height: 8.125rem; }

.h-275 {
  height: 17.1875rem; }

.h-290 {
  height: 18.125rem; }

.h-10p {
  height: 10%; }

.h-20p {
  height: 20%; }

.h-25p {
  height: 25%; }

.h-30p {
  height: 30%; }

.h-33p {
  height: 33.33333%; }

.h-40p {
  height: 40%; }

.h-49p {
  height: 49%; }

.h-50p {
  height: 50%; }

.h-60p {
  height: 60%; }

.h-66p {
  height: 66.66667%; }

.h-70p {
  height: 70%; }

.h-75p {
  height: 75%; }

.h-80p {
  height: 80%; }

.h-90p {
  height: 90%; }

.h-100p {
  height: 100%; }

.h-100vh {
  height: 100vh; }

.of-hidden {
  overflow: hidden; }

.of-x-hidden {
  overflow-x: hidden; }

.of-y-hidden {
  overflow-y: hidden; }

.of-scr {
  overflow: scroll; }

.of-x-scr {
  overflow-x: scroll; }

.of-y-scr {
  overflow-y: scroll; }

.of-y-auto {
  overflow-y: auto; }

.ow-bw {
  overflow-wrap: break-word; }

.op-10 {
  opacity: 0.1; }

.op-20 {
  opacity: 0.2; }

.op-40 {
  opacity: 0.4; }

.op-50 {
  opacity: 0.5; }

.op-100 {
  opacity: 1; }

.h-op-100:hover {
  opacity: 1; }

.ph-op-35::placeholder {
  opacity: 0.35; }

.bs {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1); }

.bp-c {
  background-position: center; }

.bs-c {
  background-size: cover; }

.br-n {
  background-repeat: no-repeat; }

/*
 * Positioning
 */
.pos-rel {
  position: relative; }

.pos-abs {
  position: absolute; }

.pos-fix {
  position: fixed; }

.fl-row {
  flex-direction: row; }

.fl-col {
  flex-direction: column; }

.jc-sb {
  justify-content: space-between; }

.jc-sa {
  justify-content: space-around; }

.jc-c {
  justify-content: center; }

.jc-fs {
  justify-content: flex-start; }

.jc-fe {
  justify-content: flex-end; }

.ai-fs {
  align-items: flex-start; }

.ai-c {
  align-items: center; }

.ai-fe {
  align-items: flex-end; }

.ai-s {
  align-items: stretch; }

.fw-w {
  flex-wrap: wrap; }

.fw-nw {
  flex-wrap: nowrap; }

.ord-1 {
  order: 1; }

.ord-2 {
  order: 2; }

.ord-3 {
  order: 3; }

.ord-4 {
  order: 4; }

/*
 * Example:
 * .t-1 => top: 15px;
 * .l-4 => left: 60px;
 */
.t-0s {
  top: 0rem; }

.t-1s {
  top: 0.9375rem; }

.t-2s {
  top: 1.875rem; }

.t-3s {
  top: 2.8125rem; }

.t-4s {
  top: 3.75rem; }

.t-a {
  top: auto; }

.b-0s {
  bottom: 0rem; }

.b-1s {
  bottom: 0.9375rem; }

.b-2s {
  bottom: 1.875rem; }

.b-3s {
  bottom: 2.8125rem; }

.b-4s {
  bottom: 3.75rem; }

.b-a {
  bottom: auto; }

.l-0s {
  left: 0rem; }

.l-1s {
  left: 0.9375rem; }

.l-2s {
  left: 1.875rem; }

.l-3s {
  left: 2.8125rem; }

.l-4s {
  left: 3.75rem; }

.l-a {
  left: auto; }

.r-0s {
  right: 0rem; }

.r-1s {
  right: 0.9375rem; }

.r-2s {
  right: 1.875rem; }

.r-3s {
  right: 2.8125rem; }

.r-4s {
  right: 3.75rem; }

.r-a {
  right: auto; }

/*
 * Example:
 * .t-10p => top: 10%;
 * .l-33p => left: 33.33333333%;
 */
.t-10p {
  top: 10%; }

.b-10p {
  bottom: 10%; }

.l-10p {
  left: 10%; }

.r-10p {
  right: 10%; }

.t-20p {
  top: 20%; }

.b-20p {
  bottom: 20%; }

.l-20p {
  left: 20%; }

.r-20p {
  right: 20%; }

.t-25p {
  top: 25%; }

.b-25p {
  bottom: 25%; }

.l-25p {
  left: 25%; }

.r-25p {
  right: 25%; }

.t-30p {
  top: 30%; }

.b-30p {
  bottom: 30%; }

.l-30p {
  left: 30%; }

.r-30p {
  right: 30%; }

.t-33p {
  top: 33.33333%; }

.b-33p {
  bottom: 33.33333%; }

.l-33p {
  left: 33.33333%; }

.r-33p {
  right: 33.33333%; }

.t-40p {
  top: 40%; }

.b-40p {
  bottom: 40%; }

.l-40p {
  left: 40%; }

.r-40p {
  right: 40%; }

.t-49p {
  top: 49%; }

.b-49p {
  bottom: 49%; }

.l-49p {
  left: 49%; }

.r-49p {
  right: 49%; }

.t-50p {
  top: 50%; }

.b-50p {
  bottom: 50%; }

.l-50p {
  left: 50%; }

.r-50p {
  right: 50%; }

.t-60p {
  top: 60%; }

.b-60p {
  bottom: 60%; }

.l-60p {
  left: 60%; }

.r-60p {
  right: 60%; }

.t-66p {
  top: 66.66667%; }

.b-66p {
  bottom: 66.66667%; }

.l-66p {
  left: 66.66667%; }

.r-66p {
  right: 66.66667%; }

.t-70p {
  top: 70%; }

.b-70p {
  bottom: 70%; }

.l-70p {
  left: 70%; }

.r-70p {
  right: 70%; }

.t-75p {
  top: 75%; }

.b-75p {
  bottom: 75%; }

.l-75p {
  left: 75%; }

.r-75p {
  right: 75%; }

.t-80p {
  top: 80%; }

.b-80p {
  bottom: 80%; }

.l-80p {
  left: 80%; }

.r-80p {
  right: 80%; }

.t-90p {
  top: 90%; }

.b-90p {
  bottom: 90%; }

.l-90p {
  left: 90%; }

.r-90p {
  right: 90%; }

.t-100p {
  top: 100%; }

.b-100p {
  bottom: 100%; }

.l-100p {
  left: 100%; }

.r-100p {
  right: 100%; }

.z-0 {
  z-index: 0; }

.z-1 {
  z-index: 1; }

.z-2 {
  z-index: 2; }

.z-n1 {
  z-index: -1; }

.tr-y-n4s {
  transform: translateY(-3.75rem); }

.tr-y-n6s {
  transform: translateY(-5.625rem); }

.f-l {
  float: left; }

.f-r {
  float: right; }

/*
 * Spacing
 */
.mt-0s {
  margin-top: 0rem; }

.mb-0s {
  margin-bottom: 0rem; }

.ml-0s {
  margin-left: 0rem; }

.mr-0s {
  margin-right: 0rem; }

.m-0s {
  margin: 0rem; }

.mx-0s {
  margin-left: 0rem;
  margin-right: 0rem; }

.my-0s {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.mt-1s {
  margin-top: 0.9375rem; }

.mb-1s {
  margin-bottom: 0.9375rem; }

.ml-1s {
  margin-left: 0.9375rem; }

.mr-1s {
  margin-right: 0.9375rem; }

.m-1s {
  margin: 0.9375rem; }

.mx-1s {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem; }

.my-1s {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem; }

.mt-2s {
  margin-top: 1.875rem; }

.mb-2s {
  margin-bottom: 1.875rem; }

.ml-2s {
  margin-left: 1.875rem; }

.mr-2s {
  margin-right: 1.875rem; }

.m-2s {
  margin: 1.875rem; }

.mx-2s {
  margin-left: 1.875rem;
  margin-right: 1.875rem; }

.my-2s {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem; }

.mt-3s {
  margin-top: 2.8125rem; }

.mb-3s {
  margin-bottom: 2.8125rem; }

.ml-3s {
  margin-left: 2.8125rem; }

.mr-3s {
  margin-right: 2.8125rem; }

.m-3s {
  margin: 2.8125rem; }

.mx-3s {
  margin-left: 2.8125rem;
  margin-right: 2.8125rem; }

.my-3s {
  margin-top: 2.8125rem;
  margin-bottom: 2.8125rem; }

.mt-4s {
  margin-top: 3.75rem; }

.mb-4s {
  margin-bottom: 3.75rem; }

.ml-4s {
  margin-left: 3.75rem; }

.mr-4s {
  margin-right: 3.75rem; }

.m-4s {
  margin: 3.75rem; }

.mx-4s {
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.my-4s {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.mt-5s {
  margin-top: 4.6875rem; }

.mb-5s {
  margin-bottom: 4.6875rem; }

.ml-5s {
  margin-left: 4.6875rem; }

.mr-5s {
  margin-right: 4.6875rem; }

.m-5s {
  margin: 4.6875rem; }

.mx-5s {
  margin-left: 4.6875rem;
  margin-right: 4.6875rem; }

.my-5s {
  margin-top: 4.6875rem;
  margin-bottom: 4.6875rem; }

.mt-6s {
  margin-top: 5.625rem; }

.mb-6s {
  margin-bottom: 5.625rem; }

.ml-6s {
  margin-left: 5.625rem; }

.mr-6s {
  margin-right: 5.625rem; }

.m-6s {
  margin: 5.625rem; }

.mx-6s {
  margin-left: 5.625rem;
  margin-right: 5.625rem; }

.my-6s {
  margin-top: 5.625rem;
  margin-bottom: 5.625rem; }

.mt-7s {
  margin-top: 6.5625rem; }

.mb-7s {
  margin-bottom: 6.5625rem; }

.ml-7s {
  margin-left: 6.5625rem; }

.mr-7s {
  margin-right: 6.5625rem; }

.m-7s {
  margin: 6.5625rem; }

.mx-7s {
  margin-left: 6.5625rem;
  margin-right: 6.5625rem; }

.my-7s {
  margin-top: 6.5625rem;
  margin-bottom: 6.5625rem; }

.mt-8s {
  margin-top: 7.5rem; }

.mb-8s {
  margin-bottom: 7.5rem; }

.ml-8s {
  margin-left: 7.5rem; }

.mr-8s {
  margin-right: 7.5rem; }

.m-8s {
  margin: 7.5rem; }

.mx-8s {
  margin-left: 7.5rem;
  margin-right: 7.5rem; }

.my-8s {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem; }

.mt-9s {
  margin-top: 8.4375rem; }

.mb-9s {
  margin-bottom: 8.4375rem; }

.ml-9s {
  margin-left: 8.4375rem; }

.mr-9s {
  margin-right: 8.4375rem; }

.m-9s {
  margin: 8.4375rem; }

.mx-9s {
  margin-left: 8.4375rem;
  margin-right: 8.4375rem; }

.my-9s {
  margin-top: 8.4375rem;
  margin-bottom: 8.4375rem; }

.mt-10s {
  margin-top: 9.375rem; }

.mb-10s {
  margin-bottom: 9.375rem; }

.ml-10s {
  margin-left: 9.375rem; }

.mr-10s {
  margin-right: 9.375rem; }

.m-10s {
  margin: 9.375rem; }

.mx-10s {
  margin-left: 9.375rem;
  margin-right: 9.375rem; }

.my-10s {
  margin-top: 9.375rem;
  margin-bottom: 9.375rem; }

.mt-11s {
  margin-top: 10.3125rem; }

.mb-11s {
  margin-bottom: 10.3125rem; }

.ml-11s {
  margin-left: 10.3125rem; }

.mr-11s {
  margin-right: 10.3125rem; }

.m-11s {
  margin: 10.3125rem; }

.mx-11s {
  margin-left: 10.3125rem;
  margin-right: 10.3125rem; }

.my-11s {
  margin-top: 10.3125rem;
  margin-bottom: 10.3125rem; }

.mt-12s {
  margin-top: 11.25rem; }

.mb-12s {
  margin-bottom: 11.25rem; }

.ml-12s {
  margin-left: 11.25rem; }

.mr-12s {
  margin-right: 11.25rem; }

.m-12s {
  margin: 11.25rem; }

.mx-12s {
  margin-left: 11.25rem;
  margin-right: 11.25rem; }

.my-12s {
  margin-top: 11.25rem;
  margin-bottom: 11.25rem; }

.mt-13s {
  margin-top: 12.1875rem; }

.mb-13s {
  margin-bottom: 12.1875rem; }

.ml-13s {
  margin-left: 12.1875rem; }

.mr-13s {
  margin-right: 12.1875rem; }

.m-13s {
  margin: 12.1875rem; }

.mx-13s {
  margin-left: 12.1875rem;
  margin-right: 12.1875rem; }

.my-13s {
  margin-top: 12.1875rem;
  margin-bottom: 12.1875rem; }

.mt-14s {
  margin-top: 13.125rem; }

.mb-14s {
  margin-bottom: 13.125rem; }

.ml-14s {
  margin-left: 13.125rem; }

.mr-14s {
  margin-right: 13.125rem; }

.m-14s {
  margin: 13.125rem; }

.mx-14s {
  margin-left: 13.125rem;
  margin-right: 13.125rem; }

.my-14s {
  margin-top: 13.125rem;
  margin-bottom: 13.125rem; }

.mt-15s {
  margin-top: 14.0625rem; }

.mb-15s {
  margin-bottom: 14.0625rem; }

.ml-15s {
  margin-left: 14.0625rem; }

.mr-15s {
  margin-right: 14.0625rem; }

.m-15s {
  margin: 14.0625rem; }

.mx-15s {
  margin-left: 14.0625rem;
  margin-right: 14.0625rem; }

.my-15s {
  margin-top: 14.0625rem;
  margin-bottom: 14.0625rem; }

.mt-16s {
  margin-top: 15rem; }

.mb-16s {
  margin-bottom: 15rem; }

.ml-16s {
  margin-left: 15rem; }

.mr-16s {
  margin-right: 15rem; }

.m-16s {
  margin: 15rem; }

.mx-16s {
  margin-left: 15rem;
  margin-right: 15rem; }

.my-16s {
  margin-top: 15rem;
  margin-bottom: 15rem; }

.mt-17s {
  margin-top: 15.9375rem; }

.mb-17s {
  margin-bottom: 15.9375rem; }

.ml-17s {
  margin-left: 15.9375rem; }

.mr-17s {
  margin-right: 15.9375rem; }

.m-17s {
  margin: 15.9375rem; }

.mx-17s {
  margin-left: 15.9375rem;
  margin-right: 15.9375rem; }

.my-17s {
  margin-top: 15.9375rem;
  margin-bottom: 15.9375rem; }

.mt-18s {
  margin-top: 16.875rem; }

.mb-18s {
  margin-bottom: 16.875rem; }

.ml-18s {
  margin-left: 16.875rem; }

.mr-18s {
  margin-right: 16.875rem; }

.m-18s {
  margin: 16.875rem; }

.mx-18s {
  margin-left: 16.875rem;
  margin-right: 16.875rem; }

.my-18s {
  margin-top: 16.875rem;
  margin-bottom: 16.875rem; }

.mt-19s {
  margin-top: 17.8125rem; }

.mb-19s {
  margin-bottom: 17.8125rem; }

.ml-19s {
  margin-left: 17.8125rem; }

.mr-19s {
  margin-right: 17.8125rem; }

.m-19s {
  margin: 17.8125rem; }

.mx-19s {
  margin-left: 17.8125rem;
  margin-right: 17.8125rem; }

.my-19s {
  margin-top: 17.8125rem;
  margin-bottom: 17.8125rem; }

.mt-20s {
  margin-top: 18.75rem; }

.mb-20s {
  margin-bottom: 18.75rem; }

.ml-20s {
  margin-left: 18.75rem; }

.mr-20s {
  margin-right: 18.75rem; }

.m-20s {
  margin: 18.75rem; }

.mx-20s {
  margin-left: 18.75rem;
  margin-right: 18.75rem; }

.my-20s {
  margin-top: 18.75rem;
  margin-bottom: 18.75rem; }

.my-01s {
  margin-top: 0.09375rem;
  margin-bottom: 0.09375rem; }

.mx-01s {
  margin-left: 0.09375rem;
  margin-right: 0.09375rem; }

.mt-01s {
  margin-top: 0.09375rem; }

.mr-01s {
  margin-right: 0.09375rem; }

.ml-01s {
  margin-left: 0.09375rem; }

.mb-01s {
  margin-bottom: 0.09375rem; }

.my-02s {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem; }

.mx-02s {
  margin-left: 0.1875rem;
  margin-right: 0.1875rem; }

.mt-02s {
  margin-top: 0.1875rem; }

.mr-02s {
  margin-right: 0.1875rem; }

.ml-02s {
  margin-left: 0.1875rem; }

.mb-02s {
  margin-bottom: 0.1875rem; }

.my-03s {
  margin-top: 0.28125rem;
  margin-bottom: 0.28125rem; }

.mx-03s {
  margin-left: 0.28125rem;
  margin-right: 0.28125rem; }

.mt-03s {
  margin-top: 0.28125rem; }

.mr-03s {
  margin-right: 0.28125rem; }

.ml-03s {
  margin-left: 0.28125rem; }

.mb-03s {
  margin-bottom: 0.28125rem; }

.my-04s {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem; }

.mx-04s {
  margin-left: 0.375rem;
  margin-right: 0.375rem; }

.mt-04s {
  margin-top: 0.375rem; }

.mr-04s {
  margin-right: 0.375rem; }

.ml-04s {
  margin-left: 0.375rem; }

.mb-04s {
  margin-bottom: 0.375rem; }

.my-05s {
  margin-top: 0.46875rem;
  margin-bottom: 0.46875rem; }

.mx-05s {
  margin-left: 0.46875rem;
  margin-right: 0.46875rem; }

.mt-05s {
  margin-top: 0.46875rem; }

.mr-05s {
  margin-right: 0.46875rem; }

.ml-05s {
  margin-left: 0.46875rem; }

.mb-05s {
  margin-bottom: 0.46875rem; }

.my-06s {
  margin-top: 0.5625rem;
  margin-bottom: 0.5625rem; }

.mx-06s {
  margin-left: 0.5625rem;
  margin-right: 0.5625rem; }

.mt-06s {
  margin-top: 0.5625rem; }

.mr-06s {
  margin-right: 0.5625rem; }

.ml-06s {
  margin-left: 0.5625rem; }

.mb-06s {
  margin-bottom: 0.5625rem; }

.my-07s {
  margin-top: 0.65625rem;
  margin-bottom: 0.65625rem; }

.mx-07s {
  margin-left: 0.65625rem;
  margin-right: 0.65625rem; }

.mt-07s {
  margin-top: 0.65625rem; }

.mr-07s {
  margin-right: 0.65625rem; }

.ml-07s {
  margin-left: 0.65625rem; }

.mb-07s {
  margin-bottom: 0.65625rem; }

.my-08s {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.mx-08s {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.mt-08s {
  margin-top: 0.75rem; }

.mr-08s {
  margin-right: 0.75rem; }

.ml-08s {
  margin-left: 0.75rem; }

.mb-08s {
  margin-bottom: 0.75rem; }

.my-09s {
  margin-top: 0.84375rem;
  margin-bottom: 0.84375rem; }

.mx-09s {
  margin-left: 0.84375rem;
  margin-right: 0.84375rem; }

.mt-09s {
  margin-top: 0.84375rem; }

.mr-09s {
  margin-right: 0.84375rem; }

.ml-09s {
  margin-left: 0.84375rem; }

.mb-09s {
  margin-bottom: 0.84375rem; }

.mr-a {
  margin-right: auto; }

.ml-a {
  margin-left: auto; }

.mt-a {
  margin-top: auto; }

.mt-n2s {
  margin-top: -1.875rem; }

.mb-n2s {
  margin-bottom: -1.875rem; }

.mb-n4s {
  margin-bottom: -3.75rem; }

.mb-n6s {
  margin-bottom: -5.625rem; }

.mx-n1s {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem; }

.pt-0s {
  padding-top: 0rem; }

.pb-0s {
  padding-bottom: 0rem; }

.pl-0s {
  padding-left: 0rem; }

.pr-0s {
  padding-right: 0rem; }

.p-0s {
  padding: 0rem; }

.px-0s {
  padding-left: 0rem;
  padding-right: 0rem; }

.py-0s {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.pt-1s {
  padding-top: 0.9375rem; }

.pb-1s {
  padding-bottom: 0.9375rem; }

.pl-1s {
  padding-left: 0.9375rem; }

.pr-1s {
  padding-right: 0.9375rem; }

.p-1s {
  padding: 0.9375rem; }

.px-1s {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem; }

.py-1s {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.pt-2s {
  padding-top: 1.875rem; }

.pb-2s {
  padding-bottom: 1.875rem; }

.pl-2s {
  padding-left: 1.875rem; }

.pr-2s {
  padding-right: 1.875rem; }

.p-2s {
  padding: 1.875rem; }

.px-2s {
  padding-left: 1.875rem;
  padding-right: 1.875rem; }

.py-2s {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

.pt-3s {
  padding-top: 2.8125rem; }

.pb-3s {
  padding-bottom: 2.8125rem; }

.pl-3s {
  padding-left: 2.8125rem; }

.pr-3s {
  padding-right: 2.8125rem; }

.p-3s {
  padding: 2.8125rem; }

.px-3s {
  padding-left: 2.8125rem;
  padding-right: 2.8125rem; }

.py-3s {
  padding-top: 2.8125rem;
  padding-bottom: 2.8125rem; }

.pt-4s {
  padding-top: 3.75rem; }

.pb-4s {
  padding-bottom: 3.75rem; }

.pl-4s {
  padding-left: 3.75rem; }

.pr-4s {
  padding-right: 3.75rem; }

.p-4s {
  padding: 3.75rem; }

.px-4s {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.py-4s {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.pt-5s {
  padding-top: 4.6875rem; }

.pb-5s {
  padding-bottom: 4.6875rem; }

.pl-5s {
  padding-left: 4.6875rem; }

.pr-5s {
  padding-right: 4.6875rem; }

.p-5s {
  padding: 4.6875rem; }

.px-5s {
  padding-left: 4.6875rem;
  padding-right: 4.6875rem; }

.py-5s {
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem; }

.pt-6s {
  padding-top: 5.625rem; }

.pb-6s {
  padding-bottom: 5.625rem; }

.pl-6s {
  padding-left: 5.625rem; }

.pr-6s {
  padding-right: 5.625rem; }

.p-6s {
  padding: 5.625rem; }

.px-6s {
  padding-left: 5.625rem;
  padding-right: 5.625rem; }

.py-6s {
  padding-top: 5.625rem;
  padding-bottom: 5.625rem; }

.pt-7s {
  padding-top: 6.5625rem; }

.pb-7s {
  padding-bottom: 6.5625rem; }

.pl-7s {
  padding-left: 6.5625rem; }

.pr-7s {
  padding-right: 6.5625rem; }

.p-7s {
  padding: 6.5625rem; }

.px-7s {
  padding-left: 6.5625rem;
  padding-right: 6.5625rem; }

.py-7s {
  padding-top: 6.5625rem;
  padding-bottom: 6.5625rem; }

.pt-8s {
  padding-top: 7.5rem; }

.pb-8s {
  padding-bottom: 7.5rem; }

.pl-8s {
  padding-left: 7.5rem; }

.pr-8s {
  padding-right: 7.5rem; }

.p-8s {
  padding: 7.5rem; }

.px-8s {
  padding-left: 7.5rem;
  padding-right: 7.5rem; }

.py-8s {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem; }

.pt-9s {
  padding-top: 8.4375rem; }

.pb-9s {
  padding-bottom: 8.4375rem; }

.pl-9s {
  padding-left: 8.4375rem; }

.pr-9s {
  padding-right: 8.4375rem; }

.p-9s {
  padding: 8.4375rem; }

.px-9s {
  padding-left: 8.4375rem;
  padding-right: 8.4375rem; }

.py-9s {
  padding-top: 8.4375rem;
  padding-bottom: 8.4375rem; }

.pt-10s {
  padding-top: 9.375rem; }

.pb-10s {
  padding-bottom: 9.375rem; }

.pl-10s {
  padding-left: 9.375rem; }

.pr-10s {
  padding-right: 9.375rem; }

.p-10s {
  padding: 9.375rem; }

.px-10s {
  padding-left: 9.375rem;
  padding-right: 9.375rem; }

.py-10s {
  padding-top: 9.375rem;
  padding-bottom: 9.375rem; }

.pt-11s {
  padding-top: 10.3125rem; }

.pb-11s {
  padding-bottom: 10.3125rem; }

.pl-11s {
  padding-left: 10.3125rem; }

.pr-11s {
  padding-right: 10.3125rem; }

.p-11s {
  padding: 10.3125rem; }

.px-11s {
  padding-left: 10.3125rem;
  padding-right: 10.3125rem; }

.py-11s {
  padding-top: 10.3125rem;
  padding-bottom: 10.3125rem; }

.pt-12s {
  padding-top: 11.25rem; }

.pb-12s {
  padding-bottom: 11.25rem; }

.pl-12s {
  padding-left: 11.25rem; }

.pr-12s {
  padding-right: 11.25rem; }

.p-12s {
  padding: 11.25rem; }

.px-12s {
  padding-left: 11.25rem;
  padding-right: 11.25rem; }

.py-12s {
  padding-top: 11.25rem;
  padding-bottom: 11.25rem; }

.pt-13s {
  padding-top: 12.1875rem; }

.pb-13s {
  padding-bottom: 12.1875rem; }

.pl-13s {
  padding-left: 12.1875rem; }

.pr-13s {
  padding-right: 12.1875rem; }

.p-13s {
  padding: 12.1875rem; }

.px-13s {
  padding-left: 12.1875rem;
  padding-right: 12.1875rem; }

.py-13s {
  padding-top: 12.1875rem;
  padding-bottom: 12.1875rem; }

.pt-14s {
  padding-top: 13.125rem; }

.pb-14s {
  padding-bottom: 13.125rem; }

.pl-14s {
  padding-left: 13.125rem; }

.pr-14s {
  padding-right: 13.125rem; }

.p-14s {
  padding: 13.125rem; }

.px-14s {
  padding-left: 13.125rem;
  padding-right: 13.125rem; }

.py-14s {
  padding-top: 13.125rem;
  padding-bottom: 13.125rem; }

.pt-15s {
  padding-top: 14.0625rem; }

.pb-15s {
  padding-bottom: 14.0625rem; }

.pl-15s {
  padding-left: 14.0625rem; }

.pr-15s {
  padding-right: 14.0625rem; }

.p-15s {
  padding: 14.0625rem; }

.px-15s {
  padding-left: 14.0625rem;
  padding-right: 14.0625rem; }

.py-15s {
  padding-top: 14.0625rem;
  padding-bottom: 14.0625rem; }

.pt-16s {
  padding-top: 15rem; }

.pb-16s {
  padding-bottom: 15rem; }

.pl-16s {
  padding-left: 15rem; }

.pr-16s {
  padding-right: 15rem; }

.p-16s {
  padding: 15rem; }

.px-16s {
  padding-left: 15rem;
  padding-right: 15rem; }

.py-16s {
  padding-top: 15rem;
  padding-bottom: 15rem; }

.pt-17s {
  padding-top: 15.9375rem; }

.pb-17s {
  padding-bottom: 15.9375rem; }

.pl-17s {
  padding-left: 15.9375rem; }

.pr-17s {
  padding-right: 15.9375rem; }

.p-17s {
  padding: 15.9375rem; }

.px-17s {
  padding-left: 15.9375rem;
  padding-right: 15.9375rem; }

.py-17s {
  padding-top: 15.9375rem;
  padding-bottom: 15.9375rem; }

.pt-18s {
  padding-top: 16.875rem; }

.pb-18s {
  padding-bottom: 16.875rem; }

.pl-18s {
  padding-left: 16.875rem; }

.pr-18s {
  padding-right: 16.875rem; }

.p-18s {
  padding: 16.875rem; }

.px-18s {
  padding-left: 16.875rem;
  padding-right: 16.875rem; }

.py-18s {
  padding-top: 16.875rem;
  padding-bottom: 16.875rem; }

.pt-19s {
  padding-top: 17.8125rem; }

.pb-19s {
  padding-bottom: 17.8125rem; }

.pl-19s {
  padding-left: 17.8125rem; }

.pr-19s {
  padding-right: 17.8125rem; }

.p-19s {
  padding: 17.8125rem; }

.px-19s {
  padding-left: 17.8125rem;
  padding-right: 17.8125rem; }

.py-19s {
  padding-top: 17.8125rem;
  padding-bottom: 17.8125rem; }

.pt-20s {
  padding-top: 18.75rem; }

.pb-20s {
  padding-bottom: 18.75rem; }

.pl-20s {
  padding-left: 18.75rem; }

.pr-20s {
  padding-right: 18.75rem; }

.p-20s {
  padding: 18.75rem; }

.px-20s {
  padding-left: 18.75rem;
  padding-right: 18.75rem; }

.py-20s {
  padding-top: 18.75rem;
  padding-bottom: 18.75rem; }

.py-01s {
  padding-top: 0.09375rem;
  padding-bottom: 0.09375rem; }

.px-01s {
  padding-left: 0.09375rem;
  padding-right: 0.09375rem; }

.py-02s {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.px-02s {
  padding-left: 0.1875rem;
  padding-right: 0.1875rem; }

.py-03s {
  padding-top: 0.28125rem;
  padding-bottom: 0.28125rem; }

.px-03s {
  padding-left: 0.28125rem;
  padding-right: 0.28125rem; }

.py-04s {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; }

.px-04s {
  padding-left: 0.375rem;
  padding-right: 0.375rem; }

.py-05s {
  padding-top: 0.46875rem;
  padding-bottom: 0.46875rem; }

.px-05s {
  padding-left: 0.46875rem;
  padding-right: 0.46875rem; }

.py-06s {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem; }

.px-06s {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem; }

.py-07s {
  padding-top: 0.65625rem;
  padding-bottom: 0.65625rem; }

.px-07s {
  padding-left: 0.65625rem;
  padding-right: 0.65625rem; }

.py-08s {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.px-08s {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.py-09s {
  padding-top: 0.84375rem;
  padding-bottom: 0.84375rem; }

.px-09s {
  padding-left: 0.84375rem;
  padding-right: 0.84375rem; }

/*
 * Colors
 */
.c-white {
  color: #FFFFFF; }

.bgc-white {
  background-color: #FFFFFF; }

.bc-white {
  border-color: #FFFFFF; }

.c-black {
  color: #000000; }

.bgc-black {
  background-color: #000000; }

.bc-black {
  border-color: #000000; }

.c-blue {
  color: #1F56CC; }

.bgc-blue {
  background-color: #1F56CC; }

.bc-blue {
  border-color: #1F56CC; }

.c-light-yellow {
  color: #FFEFD6; }

.bgc-light-yellow {
  background-color: #FFEFD6; }

.bc-light-yellow {
  border-color: #FFEFD6; }

.c-yellow {
  color: #F9C107; }

.bgc-yellow {
  background-color: #F9C107; }

.bc-yellow {
  border-color: #F9C107; }

.c-light-gray {
  color: #F1F2F7; }

.bgc-light-gray {
  background-color: #F1F2F7; }

.bc-light-gray {
  border-color: #F1F2F7; }

.c-gray {
  color: #E1E2E7; }

.bgc-gray {
  background-color: #E1E2E7; }

.bc-gray {
  border-color: #E1E2E7; }

.c-dgray {
  color: #D8D8D8; }

.bgc-dgray {
  background-color: #D8D8D8; }

.bc-dgray {
  border-color: #D8D8D8; }

.c-ddgray {
  color: #96989B; }

.bgc-ddgray {
  background-color: #96989B; }

.bc-ddgray {
  border-color: #96989B; }

.c-dark-gray {
  color: #4D5360; }

.bgc-dark-gray {
  background-color: #4D5360; }

.bc-dark-gray {
  border-color: #4D5360; }

.c-light-blue {
  color: #8497A0; }

.bgc-light-blue {
  background-color: #8497A0; }

.bc-light-blue {
  border-color: #8497A0; }

.c-green {
  color: #4AB62E; }

.bgc-green {
  background-color: #4AB62E; }

.bc-green {
  border-color: #4AB62E; }

.c-red {
  color: #DC3545; }

.bgc-red {
  background-color: #DC3545; }

.bc-red {
  border-color: #DC3545; }

.c-transparent {
  color: rgba(255, 255, 255, 0); }

.bgc-transparent {
  background-color: rgba(255, 255, 255, 0); }

.bc-transparent {
  border-color: rgba(255, 255, 255, 0); }

.c-white-op-10 {
  color: rgba(255, 255, 255, 0.1); }

.bgc-white-op-10 {
  background-color: rgba(255, 255, 255, 0.1); }

.bc-white-op-10 {
  border-color: rgba(255, 255, 255, 0.1); }

.i-gs {
  filter: grayscale(1); }

/*
 * Border radius
 */
.b-white {
  border: 1px solid #FFFFFF; }

.b-right-white {
  border-right: 1px solid #FFFFFF; }

.b-black {
  border: 1px solid #000000; }

.b-right-black {
  border-right: 1px solid #000000; }

.b-blue {
  border: 1px solid #1F56CC; }

.b-right-blue {
  border-right: 1px solid #1F56CC; }

.b-light-yellow {
  border: 1px solid #FFEFD6; }

.b-right-light-yellow {
  border-right: 1px solid #FFEFD6; }

.b-yellow {
  border: 1px solid #F9C107; }

.b-right-yellow {
  border-right: 1px solid #F9C107; }

.b-light-gray {
  border: 1px solid #F1F2F7; }

.b-right-light-gray {
  border-right: 1px solid #F1F2F7; }

.b-gray {
  border: 1px solid #E1E2E7; }

.b-right-gray {
  border-right: 1px solid #E1E2E7; }

.b-dgray {
  border: 1px solid #D8D8D8; }

.b-right-dgray {
  border-right: 1px solid #D8D8D8; }

.b-ddgray {
  border: 1px solid #96989B; }

.b-right-ddgray {
  border-right: 1px solid #96989B; }

.b-dark-gray {
  border: 1px solid #4D5360; }

.b-right-dark-gray {
  border-right: 1px solid #4D5360; }

.b-light-blue {
  border: 1px solid #8497A0; }

.b-right-light-blue {
  border-right: 1px solid #8497A0; }

.b-green {
  border: 1px solid #4AB62E; }

.b-right-green {
  border-right: 1px solid #4AB62E; }

.b-red {
  border: 1px solid #DC3545; }

.b-right-red {
  border-right: 1px solid #DC3545; }

.b-transparent {
  border: 1px solid rgba(255, 255, 255, 0); }

.b-right-transparent {
  border-right: 1px solid rgba(255, 255, 255, 0); }

.b-white-op-10 {
  border: 1px solid rgba(255, 255, 255, 0.1); }

.b-right-white-op-10 {
  border-right: 1px solid rgba(255, 255, 255, 0.1); }

.b-no {
  border: none; }

.br-2 {
  border-radius: 0.125rem; }

.br-10 {
  border-radius: 0.625rem; }

.br-20 {
  border-radius: 1.25rem; }

.br-30 {
  border-radius: 1.875rem; }

.br-50p {
  border-radius: 50%; }

/*
 * Utilities
 */
.cent-v {
  top: 50%;
  transform: translateY(-50%); }

.active-tab-trigger {
  border-color: #F9C107 !important;
  font-weight: bold; }

.shadow-overlay-right::after {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #FFFFFF); }

.shadow-overlay-sides {
  position: relative; }
  .shadow-overlay-sides::before, .shadow-overlay-sides::after {
    content: '';
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2rem; }
  .shadow-overlay-sides::before {
    left: 0;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #E1E2E7); }
  .shadow-overlay-sides::after {
    right: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #E1E2E7); }

.revealable {
  animation: grow-from-center ease 6.5s; }
  .revealable.last {
    animation: grow-from-center-last ease 6.5s; }

@keyframes grow-from-center {
  from {
    transform: scale(0.3, 0.3); }
  5% {
    transform: scale(1.1, 1.1); }
  10% {
    transform: scale(1, 1); }
  92% {
    transform: scale(1, 1); }
  95% {
    transform: scale(1.1, 1.1); }
  to {
    transform: scale(0.3, 0.3); } }

@keyframes grow-from-center-last {
  from {
    transform: scale(0.3, 0.3); }
  5% {
    transform: scale(1.1, 1.1); }
  10% {
    transform: scale(1, 1); } }

.overflow-scrolling-touch {
  -webkit-overflow-scrolling: touch; }

.image-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #698E9C;
  opacity: 0.5; }

/*
 * Plugins
 *
 * Override styling added by plugins
 */
.swiper-slide:not(.swiper-slide-active) > * {
  opacity: 0.5; }

.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  height: 0.625rem;
  width: 0.625rem;
  margin: 0 0.125rem;
  background-color: #8497A0; }

.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: #1F56CC; }

/*
 * Typography
 */
@font-face {
  font-family: 'SweetSansProBold';
  src: url("/fonts/SweetSansPro/SweetSansProBold/SweetSansProBold.otf") format("otf");
  src: url("/fonts/SweetSansPro/SweetSansProBold/SweetSansProBold.woff") format("woff");
  font-style: normal; }

@font-face {
  font-family: 'SweetSansProHeavy';
  src: url("/fonts/SweetSansPro/SweetSansProHeavy/SweetSansProHeavy.otf") format("otf");
  src: url("/fonts/SweetSansPro/SweetSansProHeavy/SweetSansProHeavy.woff") format("woff");
  font-style: normal; }

@font-face {
  font-family: 'SweetSansProMedium';
  src: url("/fonts/SweetSansPro/SweetSansProMedium/SweetSansProMedium.otf") format("otf");
  src: url("/fonts/SweetSansPro/SweetSansProMedium/SweetSansProMedium.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'SweetSansProRegular';
  src: url("/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.otf") format("otf");
  src: url("/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.eot");
  src: url("/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.eot?#iefix") format("embedded-opentype"), url("/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.woff2") format("woff2"), url("/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.woff") format("woff"), url("/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.ttf") format("truetype"), url("/fonts/SweetSansPro/SweetSansProRegular/SweetSansProRegular.svg#svgFontName") format("svg");
  font-style: normal;
  font-weight: normal; }

.ff-SweetSansPro {
  font-family: "SweetSansProRegular", sans-serif;
  font-size: 1rem;
  color: #333F58;
  line-height: 1.625rem; }
  .ff-SweetSansPro h1 {
    font-family: "SweetSansProHeavy", sans-serif;
    font-size: 2.5rem;
    line-height: 3.125rem;
    font-weight: bold; }
  .ff-SweetSansPro h2 {
    font-family: "SweetSansProBold", sans-serif;
    font-size: 2.25rem;
    line-height: 3.125rem;
    font-weight: bold; }
  .ff-SweetSansPro h3 {
    font-family: "SweetSansProBold", sans-serif;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: bold; }
  .ff-SweetSansPro h4 {
    font-family: "SweetSansProMedium", sans-serif;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: bold; }
  .ff-SweetSansPro h5 {
    font-family: "SweetSansProMedium", sans-serif;
    font-size: 1.125rem;
    font-weight: 500; }
  .ff-SweetSansPro body,
  .ff-SweetSansPro p,
  .ff-SweetSansPro span,
  .ff-SweetSansPro legend,
  .ff-SweetSansPro input,
  .ff-SweetSansPro select,
  .ff-SweetSansPro textarea {
    font-family: "SweetSansProRegular", sans-serif;
    font-size: 1rem;
    font-weight: 300; }
  .ff-SweetSansPro .font-medium {
    font-family: "SweetSansProMedium", sans-serif;
    font-size: 1.25rem;
    font-weight: 500; }

@font-face {
  font-family: "SofiaProBold";
  src: url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.eot");
  /* IE9*/
  src: url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.eot?#iefix") format("embedded-opentype"), url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.woff2") format("woff2"), url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.woff") format("woff"), url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.ttf") format("truetype"), url("/fonts/SofiaPro/SofiaProBold/SofiaProBold.svg#SofiaProLight") format("svg"); }

@font-face {
  font-family: "SofiaProRegular";
  src: url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.eot");
  /* IE9*/
  src: url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.eot?#iefix") format("embedded-opentype"), url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.woff2") format("woff2"), url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.woff") format("woff"), url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.ttf") format("truetype"), url("/fonts/SofiaPro/SofiaProRegular/SofiaProRegular.svg#SofiaProLight") format("svg"); }

@font-face {
  font-family: "SofiaProLight";
  src: url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.eot");
  /* IE9*/
  src: url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.eot?#iefix") format("embedded-opentype"), url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.woff2") format("woff2"), url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.woff") format("woff"), url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.ttf") format("truetype"), url("/fonts/SofiaPro/SofiaProLight/SofiaProLight.svg#SofiaProLight") format("svg"); }

.ff-SofiaPro {
  font-family: "SofiaProLight", sans-serif;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #171717; }
  .ff-SofiaPro body,
  .ff-SofiaPro h1,
  .ff-SofiaPro h2,
  .ff-SofiaPro h3,
  .ff-SofiaPro h4,
  .ff-SofiaPro h5,
  .ff-SofiaPro p,
  .ff-SofiaPro legend,
  .ff-SofiaPro span,
  .ff-SofiaPro input,
  .ff-SofiaPro select,
  .ff-SofiaPro textarea {
    font-weight: 400;
    font-size: 16px; }
  .ff-SofiaPro legend,
  .ff-SofiaPro span,
  .ff-SofiaPro input,
  .ff-SofiaPro textarea {
    line-height: 22px; }
  .ff-SofiaPro h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 19px 0; }
  .ff-SofiaPro h2,
  .ff-SofiaPro h3 {
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0; }
  .ff-SofiaPro h3 {
    font-size: 24px;
    font-weight: 700; }
  .ff-SofiaPro h4 {
    opacity: 50%;
    margin: 10px 0; }
  .ff-SofiaPro p {
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 10px; }
  .ff-SofiaPro legend {
    color: #161616;
    margin: 0 0 9px; }
  .ff-SofiaPro input,
  .ff-SofiaPro textarea {
    color: #2A2C32; }
  .ff-SofiaPro .fs-9 {
    font-size: 0.5625rem; }
  .ff-SofiaPro .fs-12 {
    font-size: 0.75rem; }
  .ff-SofiaPro .fs-14 {
    font-size: 0.875rem; }
  .ff-SofiaPro .fs-15 {
    font-size: 0.9375rem; }
  .ff-SofiaPro .fs-16 {
    font-size: 1rem; }
  .ff-SofiaPro .fs-18 {
    font-size: 1.125rem; }
  .ff-SofiaPro .fs-20 {
    font-size: 1.25rem; }
  .ff-SofiaPro .fs-22 {
    font-size: 1.375rem; }
  .ff-SofiaPro .fs-24 {
    font-size: 1.5rem; }
  .ff-SofiaPro .fs-26 {
    font-size: 1.625rem; }
  .ff-SofiaPro .fs-28 {
    font-size: 1.75rem; }
  .ff-SofiaPro .fs-30 {
    font-size: 1.875rem; }

.fw-light {
  font-weight: 300; }

.fw-normal {
  font-weight: 400; }

.fw-medium {
  font-weight: 500; }

.fw-bold {
  font-weight: 700; }

.fs-rem-base {
  font-size: 10px; }

.fs-9 {
  font-size: 0.5625rem; }

.fs-12 {
  font-size: 0.75rem; }

.fs-14 {
  font-size: 0.875rem; }

.fs-15 {
  font-size: 0.9375rem; }

.fs-16 {
  font-size: 1rem; }

.fs-18 {
  font-size: 1.125rem; }

.fs-20 {
  font-size: 1.25rem; }

.fs-22 {
  font-size: 1.375rem; }

.fs-24 {
  font-size: 1.5rem; }

.fs-26 {
  font-size: 1.625rem; }

.fs-28 {
  font-size: 1.75rem; }

.fs-30 {
  font-size: 1.875rem; }

.fst-italic {
  font-style: italic; }

.lh-0 {
  line-height: 0; }

.lh-13 {
  line-height: 0.8125rem; }

.lh-18 {
  line-height: 1.125rem; }

.lh-20 {
  line-height: 1.25rem; }

.lh-21 {
  line-height: 1.3125rem; }

.lh-24 {
  line-height: 1.5rem; }

.lh-27 {
  line-height: 1.6875rem; }

.lh-30 {
  line-height: 1.875rem; }

.lh-34 {
  line-height: 2.125rem; }

.lh-40 {
  line-height: 2.5rem; }

.ls-half {
  letter-spacing: 0.5px; }

.ls-norm {
  letter-spacing: normal; }

.ls-wide {
  letter-spacing: 1.5px; }

.ta-s {
  text-align: start; }

.ta-c {
  text-align: center; }

.ta-e {
  text-align: end; }

.td-lt {
  text-decoration: line-through; }

.td-no {
  text-decoration: none; }

.td-u {
  text-decoration: underline; }

.td-u-h:hover {
  text-decoration: underline; }

/*
 * Misc
 *
 * Miscellaneous classes
 */
.curs-p {
  cursor: pointer; }

.curs-g {
  cursor: grab; }

input[type='checkbox'] {
  position: relative;
  margin: 0;
  width: 1.25rem;
  height: 1.25rem; }
  input[type='checkbox']::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url("/dist/images/symbols/checkbox-unchecked.svg");
    background-size: cover;
    background-repeat: no-repeat; }
  input[type='checkbox']:checked::after {
    background-image: url("/dist/images/symbols/checkbox-checked.svg"); }

button[disabled] {
  opacity: 0.5; }

.rm-sb {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */ }

.rm-sb::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0; }

button.selected {
  outline: #1447AB auto 5px; }

.progress-circle {
  background-color: #DDDDDD;
  border-radius: 50%;
  display: inline-block;
  height: 9rem;
  margin: 1rem;
  position: relative;
  width: 9rem; }
  .progress-circle::before {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 50%;
    content: attr(data-progress) "%";
    display: flex;
    font-size: 2rem;
    justify-content: center;
    position: absolute;
    left: 0.3rem;
    right: 0.3rem;
    top: 0.3rem;
    bottom: 0.3rem; }
  .progress-circle::after {
    background-color: #1F56CC;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%; }

.progress-circle[data-progress='0']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(90deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='1']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(93.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='2']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(97.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='3']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(100.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='4']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(104.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='5']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(108deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='6']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(111.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='7']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(115.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='8']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(118.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='9']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(122.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='10']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(126deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='11']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(129.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='12']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(133.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='13']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(136.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='14']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(140.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='15']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(144deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='16']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(147.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='17']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(151.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='18']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(154.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='19']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(158.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='20']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(162deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='21']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(165.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='22']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(169.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='23']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(172.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='24']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(176.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='25']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(180deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='26']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(183.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='27']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(187.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='28']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(190.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='29']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(194.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='30']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(198deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='31']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(201.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='32']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(205.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='33']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(208.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='34']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(212.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='35']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(216deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='36']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(219.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='37']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(223.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='38']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(226.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='39']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(230.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='40']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(234deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='41']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(237.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='42']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(241.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='43']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(244.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='44']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(248.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='45']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(252deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='46']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(255.6deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='47']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(259.2deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='48']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(262.8deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='49']::after {
  background-image: linear-gradient(90deg, #DDDDDD 50%, transparent 50%, transparent), linear-gradient(266.4deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='50']::after {
  background-image: linear-gradient(-90deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='51']::after {
  background-image: linear-gradient(-86.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='52']::after {
  background-image: linear-gradient(-82.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='53']::after {
  background-image: linear-gradient(-79.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='54']::after {
  background-image: linear-gradient(-75.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='55']::after {
  background-image: linear-gradient(-72deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='56']::after {
  background-image: linear-gradient(-68.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='57']::after {
  background-image: linear-gradient(-64.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='58']::after {
  background-image: linear-gradient(-61.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='59']::after {
  background-image: linear-gradient(-57.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='60']::after {
  background-image: linear-gradient(-54deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='61']::after {
  background-image: linear-gradient(-50.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='62']::after {
  background-image: linear-gradient(-46.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='63']::after {
  background-image: linear-gradient(-43.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='64']::after {
  background-image: linear-gradient(-39.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='65']::after {
  background-image: linear-gradient(-36deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='66']::after {
  background-image: linear-gradient(-32.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='67']::after {
  background-image: linear-gradient(-28.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='68']::after {
  background-image: linear-gradient(-25.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='69']::after {
  background-image: linear-gradient(-21.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='70']::after {
  background-image: linear-gradient(-18deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='71']::after {
  background-image: linear-gradient(-14.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='72']::after {
  background-image: linear-gradient(-10.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='73']::after {
  background-image: linear-gradient(-7.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='74']::after {
  background-image: linear-gradient(-3.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='75']::after {
  background-image: linear-gradient(0deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='76']::after {
  background-image: linear-gradient(3.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='77']::after {
  background-image: linear-gradient(7.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='78']::after {
  background-image: linear-gradient(10.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='79']::after {
  background-image: linear-gradient(14.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='80']::after {
  background-image: linear-gradient(18deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='81']::after {
  background-image: linear-gradient(21.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='82']::after {
  background-image: linear-gradient(25.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='83']::after {
  background-image: linear-gradient(28.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='84']::after {
  background-image: linear-gradient(32.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='85']::after {
  background-image: linear-gradient(36deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='86']::after {
  background-image: linear-gradient(39.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='87']::after {
  background-image: linear-gradient(43.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='88']::after {
  background-image: linear-gradient(46.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='89']::after {
  background-image: linear-gradient(50.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='90']::after {
  background-image: linear-gradient(54deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='91']::after {
  background-image: linear-gradient(57.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='92']::after {
  background-image: linear-gradient(61.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='93']::after {
  background-image: linear-gradient(64.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='94']::after {
  background-image: linear-gradient(68.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='95']::after {
  background-image: linear-gradient(72deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='96']::after {
  background-image: linear-gradient(75.6deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='97']::after {
  background-image: linear-gradient(79.2deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='98']::after {
  background-image: linear-gradient(82.8deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='99']::after {
  background-image: linear-gradient(86.4deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.progress-circle[data-progress='100']::after {
  background-image: linear-gradient(90deg, #1F56CC 50%, transparent 50%, transparent), linear-gradient(270deg, #1F56CC 50%, #DDDDDD 50%, #DDDDDD); }

.modal {
  left: 0;
  top: 0;
  overflow: auto;
  background-color: black;
  background-color: rgba(100, 100, 100, 0.7);
  font-size: 18px;
  line-height: 26px; }
  .modal .modal-content {
    box-shadow: 0 0 5px #101929; }
  .modal p {
    margin-bottom: 1rem; }
  .modal h4 {
    margin-top: 0;
    margin-bottom: 5px; }
  .modal li {
    margin-bottom: 10px; }
    .modal li:last-child {
      margin-bottom: 0; }
  .modal ul {
    list-style-type: disc;
    list-style-position: inside; }
  .modal ol {
    list-style-type: decimal;
    list-style-position: inside; }
  .modal ul ul,
  .modal ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px; }
  .modal ol ol,
  .modal ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px; }

.close:hover,
.close:focus {
  color: gray;
  text-decoration: none;
  cursor: pointer; }

@media (min-width: 992px) {
  .welcome {
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right;
    background-image: url("/dist/images/welcome/bg-img.png"); } }

@media (min-width: 992px) {
  nav.navbar-mobile {
    display: none; } }

nav.navbar-mobile button.navbar-toggler {
  padding: 0;
  outline: 0; }

nav.navbar-mobile .navbar-collapse {
  position: absolute;
  top: 66px;
  left: 0;
  z-index: 1;
  height: calc(100vh - 60px);
  width: 100%; }

nav.navbar-mobile .animated-icon {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer; }
  nav.navbar-mobile .animated-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    background: #000000;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out; }
    nav.navbar-mobile .animated-icon span:nth-child(1) {
      top: 0; }
    nav.navbar-mobile .animated-icon span:nth-child(2), nav.navbar-mobile .animated-icon span:nth-child(3) {
      top: 10px; }
    nav.navbar-mobile .animated-icon span:nth-child(4) {
      top: 20px; }
  nav.navbar-mobile .animated-icon.open span:nth-child(1) {
    top: 11px;
    width: 0;
    left: 50%; }
  nav.navbar-mobile .animated-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg); }
  nav.navbar-mobile .animated-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  nav.navbar-mobile .animated-icon.open span:nth-child(4) {
    top: 11px;
    width: 0;
    left: 50%; }

nav.navbar-desktop {
  display: none; }
  @media (min-width: 992px) {
    nav.navbar-desktop {
      display: flex; }
      nav.navbar-desktop a.nav-link:hover {
        font-weight: bold; } }

.btn-primary {
  background-color: #1F56CC;
  border-color: #1F56CC; }

.application {
  box-sizing: border-box;
  height: 762px;
  width: 1444px;
  max-height: 762px;
  max-width: 1444px; }
  .application .content {
    height: 760px;
    max-height: 760px; }

.benefits {
  background-color: #F1F2F7; }
  .benefits .overview {
    background-color: white; }

@media (min-width: 576px) {
  .benefits .overview {
    background-color: #F1F2F7; } }

@media (min-width: 992px) {
  .benefits {
    background-color: white; }
    .benefits .overview {
      background-color: white; } }

.hiw .hiw-accordion a {
  display: flex;
  align-items: center;
  cursor: pointer; }
  .hiw .hiw-accordion a span {
    font-size: 0.875rem;
    margin-left: 0.5rem; }
  .hiw .hiw-accordion a[aria-expanded="true"] svg circle.bg {
    fill: #1F56CC; }
  .hiw .hiw-accordion a[aria-expanded="true"] svg path.shape {
    fill: #FFFFFF; }
  .hiw .hiw-accordion a[aria-expanded="true"] span {
    font-size: 1rem;
    font-weight: 500; }

.hiw .image-holder img {
  display: none; }
  .hiw .image-holder img.show {
    display: block; }

@media (min-width: 992px) {
  .hiw .hiw-accordion a span {
    font-size: 1rem;
    margin-left: 0.5rem; }
    .hiw .hiw-accordion a span[aria-expanded="true"] svg circle.bg {
      fill: #1F56CC; }
    .hiw .hiw-accordion a span[aria-expanded="true"] svg path.shape {
      fill: #FFFFFF; }
    .hiw .hiw-accordion a span[aria-expanded="true"] span {
      font-size: 1.125rem;
      font-weight: 500; }
  .hiw .image-holder img {
    border: 2px solid lightgray;
    box-shadow: 2px 4px 5px lightgray; } }

.mediators .fader {
  transition: opacity 0.5s linear; }

.mediators .fade {
  opacity: 0; }

@media (min-width: 992px) {
  .mediators select {
    width: 30%; } }

.faq .faq-accordion a {
  font-size: 1.125rem;
  position: relative;
  cursor: pointer; }
  .faq .faq-accordion a::before {
    position: absolute;
    left: -1.5em;
    top: -0;
    width: 1em;
    height: 1.3em;
    content: "";
    background-image: url("/dist/images/symbols/faq/plus.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding-left: 0.2em;
    padding-right: 0.2em;
    pointer-events: none; }
  .faq .faq-accordion a[aria-expanded="true"] {
    font-weight: bold; }
    .faq .faq-accordion a[aria-expanded="true"]::before {
      background-image: url("/dist/images/symbols/faq/min.svg"); }

.faq .hidden {
  display: none; }

.contact {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url("/dist/images/contact-img-small.svg"); }
  .contact h2 {
    font-size: 28px; }
  .contact h3 {
    font-size: 20px; }
  .contact p {
    font-size: 16px;
    margin-bottom: 4px; }
  .contact p.font-weight-light {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 2px; }
  .contact .form-fields {
    margin-bottom: 3rem; }
    .contact .form-fields label {
      margin-bottom: 10px; }

@media (min-width: 768px) {
  .contact {
    background-image: url("/dist/images/contact-img-big.svg"); } }

@media (min-width: 992px) {
  .contact h2 {
    font-size: 36px; }
  .contact h3 {
    font-size: 24px; }
  .contact p {
    font-size: 20px;
    margin-bottom: 6px;
    line-height: 28px; }
  .contact p.font-weight-light {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px; }
  .contact .form-fields {
    width: 65%; }
  .contact .form-information {
    width: 35%;
    margin-bottom: 3rem; } }

.privacy-statement h1,
.terms-and-conditions h1 {
  font-size: 36px; }

.privacy-statement h2,
.terms-and-conditions h2 {
  font-size: 24px; }

.privacy-statement h3,
.terms-and-conditions h3 {
  font-size: 22px;
  margin-top: 15px;
  margin-bottom: 5px; }

.privacy-statement p,
.terms-and-conditions p {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 22px; }
  .privacy-statement p.font-weight-light,
  .terms-and-conditions p.font-weight-light {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 14px; }
    .privacy-statement p.font-weight-light.list-item,
    .terms-and-conditions p.font-weight-light.list-item {
      margin-bottom: 5px; }

.privacy-statement li,
.terms-and-conditions li {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 22px; }

.privacy-statement ul,
.terms-and-conditions ul {
  padding-left: 30px;
  margin-bottom: 10px;
  list-style-type: disc;
  list-style-position: outside; }

.privacy-statement ol,
.terms-and-conditions ol {
  padding-left: 30px;
  margin-bottom: 0;
  list-style-type: decimal;
  list-style-position: outside; }

.privacy-statement ul ul,
.privacy-statement ol ul,
.terms-and-conditions ul ul,
.terms-and-conditions ol ul {
  list-style-type: circle;
  list-style-position: outside;
  margin-left: 15px; }

.privacy-statement ol ol,
.privacy-statement ul ol,
.terms-and-conditions ol ol,
.terms-and-conditions ul ol {
  list-style-type: lower-latin;
  list-style-position: outside;
  margin-left: 15px; }

.footer a:hover {
  color: #96989B; }

/*
 * Container
 */
/*
 * Display
 */
@media only screen and (min-width: 1024px) {
  .T-d-fl {
    display: flex; }
  .T-d-bl {
    display: block; }
  .T-d-ibl {
    display: inline-block; }
  .T-d-no {
    display: none; }
  .T-w-full {
    width: 100%; }
  .T-h-110 {
    height: 6.875rem; }
  .T-h-210 {
    height: 13.125rem; }
  .T-h-250 {
    height: 15.625rem; }
  .T-h-310 {
    height: 19.375rem; }
  .T-h-442 {
    height: 27.625rem; }
  .T-h-500 {
    height: 31.25rem; }
  .T-h-502 {
    height: 31.375rem; }
  .T-mw-360 {
    max-width: 22.5rem; }
  .T-mw-463 {
    max-width: 28.9375rem; }
  .T-mw-500 {
    max-width: 31.25rem; }
  .T-mw-600 {
    max-width: 37.5rem; }
  .T-mw-630 {
    max-width: 39.375rem; }
  .T-mw-720 {
    max-width: 45rem; }
  .T-mw-760 {
    max-width: 47.5rem; }
  .T-mw-830 {
    max-width: 51.875rem; }
  .T-mw-850 {
    max-width: 53.125rem; }
  .T-miw-33p {
    min-width: calc(100% / 3); }
  .T-mw-25p {
    max-width: 25%; }
  .T-mw-33p {
    max-width: calc(100% / 3); }
  .T-mw-540 {
    max-width: 33.75rem; }
  .T-mw-800 {
    max-width: 50rem; }
  .T-w-30p {
    width: 30%; }
  .T-w-40p {
    width: 40%; }
  .T-w-48p {
    width: 48%; }
  .T-w-50p {
    width: 50%; }
  .T-w-70p {
    width: 70%; }
  .T-w-100p {
    width: 100%; }
  .T-w-33p {
    width: calc(100% / 3); }
  .T-w-66p {
    width: calc((100% / 3) * 2); }
  .T-w-a {
    width: auto; }
  .T-w-170 {
    width: 10.625rem; }
  .T-w-250 {
    width: 15.625rem; }
  .T-w-275 {
    width: 17.1875rem; }
  .T-w-500 {
    width: 31.25rem; }
  .T-w-550 {
    width: 34.375rem; }
  .T-w-700 {
    width: 43.75rem; }
  .T-w-730 {
    width: 45.625rem; }
  .T-w-820 {
    width: 51.25rem; }
  .T-mh-300 {
    max-height: 18.75rem; }
  .T-mih-300 {
    min-height: 18.75rem; }
  .T-mw-700 {
    max-width: 43.75rem; }
  .T-of-auto {
    overflow: auto; }
  .T-op-100 {
    opacity: 1; }
  .T-ts {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }
  .T-bs-no {
    box-shadow: none; }
  .T-bs {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1); } }

/*
 * Positioning
 */
@media only screen and (min-width: 1024px) {
  .T-pos-rel {
    position: relative; }
  .T-pos-abs {
    position: absolute; }
  .T-pos-sti {
    position: sticky; }
  .T-fl-col {
    flex-direction: column; }
  .T-fl-row {
    flex-direction: row; }
  .T-ai-c {
    align-items: center; }
  .T-ai-s {
    align-items: stretch; }
  .T-ai-st {
    align-items: start; }
  .T-jc-sb {
    justify-content: space-between; }
  .T-jc-fs {
    justify-content: flex-start; }
  .T-jc-fe {
    justify-content: flex-end; }
  .T-jc-c {
    justify-content: center; }
  .T-fw-w {
    flex-wrap: wrap; }
  .T-ord-1 {
    order: 1; }
  .T-ord-2 {
    order: 2; }
  .T-ord-3 {
    order: 3; }
  .T-ord-4 {
    order: 4; }
  /* Top/Right/Bottom/Left
 *
 * Example:
 * .T-t-1 => top: 15px;
 * .T-l-4 => left: 60px;
 */
  .T-t-0s {
    top: 0rem; }
  .T-t-1s {
    top: 0.9375rem; }
  .T-t-2s {
    top: 1.875rem; }
  .T-t-3s {
    top: 2.8125rem; }
  .T-t-4s {
    top: 3.75rem; }
  .T-t-a {
    top: auto; }
  .T-b-0s {
    bottom: 0rem; }
  .T-b-1s {
    bottom: 0.9375rem; }
  .T-b-2s {
    bottom: 1.875rem; }
  .T-b-3s {
    bottom: 2.8125rem; }
  .T-b-4s {
    bottom: 3.75rem; }
  .T-b-a {
    bottom: auto; }
  .T-l-0s {
    left: 0rem; }
  .T-l-1s {
    left: 0.9375rem; }
  .T-l-2s {
    left: 1.875rem; }
  .T-l-3s {
    left: 2.8125rem; }
  .T-l-4s {
    left: 3.75rem; }
  .T-l-a {
    left: auto; }
  .T-r-0s {
    right: 0rem; }
  .T-r-1s {
    right: 0.9375rem; }
  .T-r-2s {
    right: 1.875rem; }
  .T-r-3s {
    right: 2.8125rem; }
  .T-r-4s {
    right: 3.75rem; }
  .T-r-a {
    right: auto; }
  .T-r-n2s {
    right: -1.875rem; }
  .T-t-120 {
    top: 7.5rem; }
  .T-tr-y-n6s {
    transform: translateY(-5.625rem); }
  .T-tr-y-n40 {
    transform: translateY(-2.5rem); } }

/*
 * Spacing
 */
@media only screen and (min-width: 1024px) {
  .T-mt-0s {
    margin-top: 0rem; }
  .T-mb-0s {
    margin-bottom: 0rem; }
  .T-ml-0s {
    margin-left: 0rem; }
  .T-mr-0s {
    margin-right: 0rem; }
  .T-m-0s {
    margin: 0rem; }
  .T-mx-0s {
    margin-left: 0rem;
    margin-right: 0rem; }
  .T-my-0s {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .T-mt-1s {
    margin-top: 0.9375rem; }
  .T-mb-1s {
    margin-bottom: 0.9375rem; }
  .T-ml-1s {
    margin-left: 0.9375rem; }
  .T-mr-1s {
    margin-right: 0.9375rem; }
  .T-m-1s {
    margin: 0.9375rem; }
  .T-mx-1s {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem; }
  .T-my-1s {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem; }
  .T-mt-2s {
    margin-top: 1.875rem; }
  .T-mb-2s {
    margin-bottom: 1.875rem; }
  .T-ml-2s {
    margin-left: 1.875rem; }
  .T-mr-2s {
    margin-right: 1.875rem; }
  .T-m-2s {
    margin: 1.875rem; }
  .T-mx-2s {
    margin-left: 1.875rem;
    margin-right: 1.875rem; }
  .T-my-2s {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .T-mt-3s {
    margin-top: 2.8125rem; }
  .T-mb-3s {
    margin-bottom: 2.8125rem; }
  .T-ml-3s {
    margin-left: 2.8125rem; }
  .T-mr-3s {
    margin-right: 2.8125rem; }
  .T-m-3s {
    margin: 2.8125rem; }
  .T-mx-3s {
    margin-left: 2.8125rem;
    margin-right: 2.8125rem; }
  .T-my-3s {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem; }
  .T-mt-4s {
    margin-top: 3.75rem; }
  .T-mb-4s {
    margin-bottom: 3.75rem; }
  .T-ml-4s {
    margin-left: 3.75rem; }
  .T-mr-4s {
    margin-right: 3.75rem; }
  .T-m-4s {
    margin: 3.75rem; }
  .T-mx-4s {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .T-my-4s {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .T-mt-5s {
    margin-top: 4.6875rem; }
  .T-mb-5s {
    margin-bottom: 4.6875rem; }
  .T-ml-5s {
    margin-left: 4.6875rem; }
  .T-mr-5s {
    margin-right: 4.6875rem; }
  .T-m-5s {
    margin: 4.6875rem; }
  .T-mx-5s {
    margin-left: 4.6875rem;
    margin-right: 4.6875rem; }
  .T-my-5s {
    margin-top: 4.6875rem;
    margin-bottom: 4.6875rem; }
  .T-mt-6s {
    margin-top: 5.625rem; }
  .T-mb-6s {
    margin-bottom: 5.625rem; }
  .T-ml-6s {
    margin-left: 5.625rem; }
  .T-mr-6s {
    margin-right: 5.625rem; }
  .T-m-6s {
    margin: 5.625rem; }
  .T-mx-6s {
    margin-left: 5.625rem;
    margin-right: 5.625rem; }
  .T-my-6s {
    margin-top: 5.625rem;
    margin-bottom: 5.625rem; }
  .T-mb-n6s {
    margin-bottom: -5.625rem; }
  .T-pt-0s {
    padding-top: 0rem; }
  .T-pb-0s {
    padding-bottom: 0rem; }
  .T-pl-0s {
    padding-left: 0rem; }
  .T-pr-0s {
    padding-right: 0rem; }
  .T-p-0s {
    padding: 0rem; }
  .T-px-0s {
    padding-left: 0rem;
    padding-right: 0rem; }
  .T-py-0s {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .T-pt-1s {
    padding-top: 0.9375rem; }
  .T-pb-1s {
    padding-bottom: 0.9375rem; }
  .T-pl-1s {
    padding-left: 0.9375rem; }
  .T-pr-1s {
    padding-right: 0.9375rem; }
  .T-p-1s {
    padding: 0.9375rem; }
  .T-px-1s {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .T-py-1s {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; }
  .T-pt-2s {
    padding-top: 1.875rem; }
  .T-pb-2s {
    padding-bottom: 1.875rem; }
  .T-pl-2s {
    padding-left: 1.875rem; }
  .T-pr-2s {
    padding-right: 1.875rem; }
  .T-p-2s {
    padding: 1.875rem; }
  .T-px-2s {
    padding-left: 1.875rem;
    padding-right: 1.875rem; }
  .T-py-2s {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .T-pt-3s {
    padding-top: 2.8125rem; }
  .T-pb-3s {
    padding-bottom: 2.8125rem; }
  .T-pl-3s {
    padding-left: 2.8125rem; }
  .T-pr-3s {
    padding-right: 2.8125rem; }
  .T-p-3s {
    padding: 2.8125rem; }
  .T-px-3s {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem; }
  .T-py-3s {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem; }
  .T-pt-4s {
    padding-top: 3.75rem; }
  .T-pb-4s {
    padding-bottom: 3.75rem; }
  .T-pl-4s {
    padding-left: 3.75rem; }
  .T-pr-4s {
    padding-right: 3.75rem; }
  .T-p-4s {
    padding: 3.75rem; }
  .T-px-4s {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .T-py-4s {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .T-pt-5s {
    padding-top: 4.6875rem; }
  .T-pb-5s {
    padding-bottom: 4.6875rem; }
  .T-pl-5s {
    padding-left: 4.6875rem; }
  .T-pr-5s {
    padding-right: 4.6875rem; }
  .T-p-5s {
    padding: 4.6875rem; }
  .T-px-5s {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem; }
  .T-py-5s {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem; }
  .T-pt-6s {
    padding-top: 5.625rem; }
  .T-pb-6s {
    padding-bottom: 5.625rem; }
  .T-pl-6s {
    padding-left: 5.625rem; }
  .T-pr-6s {
    padding-right: 5.625rem; }
  .T-p-6s {
    padding: 5.625rem; }
  .T-px-6s {
    padding-left: 5.625rem;
    padding-right: 5.625rem; }
  .T-py-6s {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem; }
  .T-pt-7s {
    padding-top: 6.5625rem; }
  .T-pb-7s {
    padding-bottom: 6.5625rem; }
  .T-pl-7s {
    padding-left: 6.5625rem; }
  .T-pr-7s {
    padding-right: 6.5625rem; }
  .T-p-7s {
    padding: 6.5625rem; }
  .T-px-7s {
    padding-left: 6.5625rem;
    padding-right: 6.5625rem; }
  .T-py-7s {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem; }
  .T-pb-235 {
    padding-bottom: 14.6875rem; }
  .T-pt-11s {
    padding-top: 10.3125rem; }
  .T-pt-14s {
    padding-top: 13.125rem; } }

/*
 * Colors
 */
@media only screen and (min-width: 1024px) {
  .T-c-white {
    color: #FFFFFF; }
  .T-bgc-white {
    background-color: #FFFFFF; }
  .T-c-black {
    color: #000000; }
  .T-bgc-black {
    background-color: #000000; }
  .T-c-blue {
    color: #1F56CC; }
  .T-bgc-blue {
    background-color: #1F56CC; }
  .T-c-light-yellow {
    color: #FFEFD6; }
  .T-bgc-light-yellow {
    background-color: #FFEFD6; }
  .T-c-yellow {
    color: #F9C107; }
  .T-bgc-yellow {
    background-color: #F9C107; }
  .T-c-light-gray {
    color: #F1F2F7; }
  .T-bgc-light-gray {
    background-color: #F1F2F7; }
  .T-c-gray {
    color: #E1E2E7; }
  .T-bgc-gray {
    background-color: #E1E2E7; }
  .T-c-dgray {
    color: #D8D8D8; }
  .T-bgc-dgray {
    background-color: #D8D8D8; }
  .T-c-ddgray {
    color: #96989B; }
  .T-bgc-ddgray {
    background-color: #96989B; }
  .T-c-dark-gray {
    color: #4D5360; }
  .T-bgc-dark-gray {
    background-color: #4D5360; }
  .T-c-light-blue {
    color: #8497A0; }
  .T-bgc-light-blue {
    background-color: #8497A0; }
  .T-c-green {
    color: #4AB62E; }
  .T-bgc-green {
    background-color: #4AB62E; }
  .T-c-red {
    color: #DC3545; }
  .T-bgc-red {
    background-color: #DC3545; }
  .T-c-transparent {
    color: rgba(255, 255, 255, 0); }
  .T-bgc-transparent {
    background-color: rgba(255, 255, 255, 0); }
  .T-c-white-op-10 {
    color: rgba(255, 255, 255, 0.1); }
  .T-bgc-white-op-10 {
    background-color: rgba(255, 255, 255, 0.1); } }

/*
 * Typography
 */
@media only screen and (min-width: 1024px) {
  .T-fs-16 {
    font-size: 1rem; }
  .T-fs-18 {
    font-size: 1.125rem; }
  .T-fs-20 {
    font-size: 1.25rem; }
  .T-fs-22 {
    font-size: 1.375rem; }
  .T-fs-26 {
    font-size: 1.625rem; }
  .T-fs-32 {
    font-size: 2rem; }
  .T-fs-48 {
    font-size: 3rem; }
  .T-fs-72 {
    font-size: 4.5rem; }
  .T-lh-17 {
    line-height: 1.0625rem; }
  .T-lh-26 {
    line-height: 1.625rem; }
  .T-lh-28 {
    line-height: 1.75rem; }
  .T-lh-39 {
    line-height: 2.4375rem; }
  .T-lh-48 {
    line-height: 3rem; }
  .T-lh-68 {
    line-height: 4.25rem; }
  .T-fw-normal {
    font-weight: normal; }
  .T-ta-s {
    text-align: start; }
  .T-ta-e {
    text-align: end; } }

.td-lt {
  text-decoration: line-through; }

.td-u {
  text-decoration: underline; }

.td-u-h:hover {
  text-decoration: underline; }

/*
 * Border radius
 */
@media only screen and (min-width: 1024px) {
  .T-bb-n {
    border-bottom: none; }
  .T-br-10 {
    border-radius: 0.625rem; }
  .T-h-bd-b:hover {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
    margin-bottom: -1px; }
  .T-h-bd-b-scroll:hover {
    border-bottom: 1px dashed rgba(56, 85, 99, 0.5); }
  .T-b-white {
    border: 1px solid #FFFFFF; }
  .T-b-light-gray {
    border: 1px solid #F1F2F7; }
  .T-br-l-10 {
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem; }
  .T-br-r-10 {
    border-top-right-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem; }
  .T-br-tl-10 {
    border-top-left-radius: 0.625rem; }
  .T-br-tr-10 {
    border-top-right-radius: 0.625rem; }
  .T-br-t-10 {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem; }
  .T-br-b-10 {
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem; } }

/*
 * Utilities
 */
@media only screen and (min-width: 1024px) {
  .shadow-overlay-sides::before, .shadow-overlay-sides::after {
    width: 3rem; }
  .T-hp:hover.T-hp-mb-n15s {
    margin-bottom: -14.0625rem; }
  .T-hp:hover.T-hp-z-1 {
    z-index: 1; }
  .T-hp:hover.T-hp-bs {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1); }
  .T-hp:hover .T-hc-d-bl {
    display: block; }
  .T-hp:hover .T-hc-mb-1s {
    margin-bottom: 0.9375rem; }
  .T-page-header:not(.T-scrolled) {
    box-shadow: none;
    flex-direction: column;
    position: absolute;
    transition: background-color 0.5s ease-out; }
    .T-page-header:not(.T-scrolled) .menu-content a {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }
    .T-page-header:not(.T-scrolled) .logo-common {
      display: none; }
  .T-page-header.T-scrolled {
    background-color: #FFFFFF;
    transition: none; }
    .T-page-header.T-scrolled .menu-content a {
      color: #1F56CC; }
      .T-page-header.T-scrolled .menu-content a:hover {
        border-bottom: 1px dashed rgba(56, 85, 99, 0.5); }
    .T-page-header.T-scrolled .logo-common img {
      height: 3.75rem; }
    .T-page-header.T-scrolled .logo-tablet {
      display: none; }
  .T-movement-animation .up-down {
    animation: MoveUpDown 45s linear infinite; }
  .T-movement-animation .down-up {
    animation: MoveDownUp 45s linear infinite; }
  @keyframes MoveUpDown {
    0% {
      transform: translateY(0%); }
    50% {
      transform: translateY(-100px); }
    100% {
      transform: translateY(0%); } }
  @keyframes MoveDownUp {
    0% {
      transform: translateY(0%); }
    50% {
      transform: translateY(100px); }
    100% {
      transform: translateY(0%); } } }

/*
 * Container
 */
/*
 *  Desktop breakpoint is variable because we want the screen to switch to
 *  desktop view as soon as the content ($container-size) reaches max width plus
 *  twice the size of the x-padding on tablet.
 */
.yquery-modal-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), none; }

.yquery-modal {
  display: none;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background-color: white;
  padding: 1.875rem;
  min-width: 30%;
  border: solid 1px #666666;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
  .yquery-modal .close-yquery-modal {
    position: absolute;
    right: 0.75rem;
    top: 0;
    padding: 0.625rem;
    color: #AAAAAA;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    cursor: pointer; }

.et-hidden,
.et-edit-mode-notification.et-flyout-open .flyout.open,
.et-edit-mode-notification:not(.et-flyout-open) .flyout.close,
.et-editable-text-old {
  display: none; }

body.disable-hover {
  pointer-events: none; }

.et-edit-modal .button,
.et-edit-mode-notification .button {
  display: block;
  padding: 0.9375rem 1.875rem;
  border-radius: 0.25rem;
  background-color: #567482;
  cursor: pointer;
  color: #FFFFFF; }

.et-edit-mode-notification {
  background-color: whitesmoke;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 2;
  transform: translate(calc(-100% + 45px), -50%);
  border-radius: 0 0.25rem 0.25rem 0;
  box-shadow: 0 0.125rem 0.25rem rgba(34, 34, 34, 0.3);
  padding: 0.9375rem 2.8125rem 0.9375rem 0.9375rem;
  transition: transform 0.5s ease-out;
  font-size: 1.375rem;
  font-weight: bold; }
  .et-edit-mode-notification.et-flyout-open {
    transform: translate(0, -50%); }
  .et-edit-mode-notification .button.publish {
    background-color: #F9C107; }
  .et-edit-mode-notification .button:not(.publish) {
    margin-top: 0.9375rem; }
  .et-edit-mode-notification .flyout {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0.625rem 0.3125rem;
    cursor: pointer; }
    .et-edit-mode-notification .flyout svg {
      width: 1.875rem;
      height: 1.875rem; }
  .et-edit-mode-notification .et-edit-mode-label {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.625rem 0.4375rem;
    writing-mode: vertical-lr; }

.et-editable-text {
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative; }
  .et-editable-text:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), none;
    border: 0.0625rem solid grey;
    border-radius: 0.25rem;
    margin: -0.0625rem; }
    .et-editable-text:hover .et-edit-text-button {
      display: block; }

.et-edit-text-button {
  display: none;
  width: auto;
  padding: 0.3125rem;
  position: absolute;
  top: -0.0625rem;
  right: -0.0625rem;
  line-height: 0;
  border-radius: 0.25rem;
  background-color: #F9C107;
  color: #FFFFFF;
  cursor: pointer; }
  .et-edit-text-button svg.fa-magic {
    width: 1.25rem;
    height: 1.25rem; }

.et-edit-modal .h2 {
  font-size: 1.375rem;
  font-weight: bold;
  text-align: center; }

.et-edit-modal .et-text-area {
  resize: vertical;
  width: 100%;
  margin: 0.9375rem 0;
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  padding: 0.625rem;
  box-sizing: border-box; }

.et-edit-modal .et-button-container {
  display: flex;
  justify-content: space-around; }

.et-edit-modal .button {
  border-radius: 0.25rem;
  max-width: 40%; }

.et-edit-modal .revert {
  background-color: #567482; }

.et-editable-image:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), none;
  border: 0.0625rem solid #F9C107;
  border-radius: 0.25rem;
  margin: -0.0625rem; }
  .et-editable-image:hover .et-edit-text-button {
    display: block; }

.et-loader {
  display: none;
  width: 100%;
  height: 100vh;
  z-index: 3;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7); }
  .et-loader .svg-container {
    margin: auto; }
