// Progress Circle
$loaded-color: #1F56CC;
$unloaded-color: #DDDDDD;
$size: 9rem;

.progress-circle {
  background-color: $unloaded-color;
  border-radius: 50%;
  display: inline-block;
  height: $size;
  margin: 1rem;
  position: relative;
  width: $size;

  &::before {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 50%;
    content: attr(data-progress)'%';
    display: flex;
    font-size: 2rem;
    justify-content: center;
    position: absolute;
    left: 0.3rem;
    right: 0.3rem;
    top: 0.3rem;
    bottom: 0.3rem;
  }

  &::after {
    background-color: $loaded-color;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
  }
}

$step: 1;
$loops: round(100 / $step);
$increment: 360 / $loops;
$half: round($loops / 2);

@for $i from 0 through $loops {
  .progress-circle[data-progress='#{$i * $step}']::after {
    @if ($i < $half) {
      $next-deg: 90deg + $increment * $i;
      background-image: linear-gradient(90deg, $unloaded-color 50%, transparent 50%, transparent),
      linear-gradient($next-deg, $loaded-color 50%, $unloaded-color 50%, $unloaded-color);
    } @else {
      $next-deg: -90deg + $increment * ($i - $half);
      background-image: linear-gradient($next-deg, $loaded-color 50%, transparent 50%, transparent),
      linear-gradient(270deg, $loaded-color 50%, $unloaded-color 50%, $unloaded-color);
    }
  }
}
