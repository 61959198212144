.et-loader {
  display: none;
  width: 100%;
  height: 100vh;
  z-index: 3;
  position: fixed;
  top: 0;
  background-color: rgba(0,0,0,.7);

  .svg-container {
    margin: auto;
  }
}
