/*
 * Container
 */
$container-size: rem-calc(1170);

/*
 *  Desktop breakpoint is variable because we want the screen to switch to
 *  desktop view as soon as the content ($container-size) reaches max width plus
 *  twice the size of the x-padding on tablet.
 */
$desktop-breakpoint: #{(strip-unit($container-size) + 2 * strip-unit($spacer)) * $rem-base};

$desktop: "only screen and (min-width:#{$desktop-breakpoint})";
//
//.container {
//  @media #{$desktop} {
//    padding-left: calc((100vw - #{$container-size}) / 2);
//    padding-right: calc((100vw - #{$container-size}) / 2);
//  }
//}
