/*
 * Border radius
 */

// Variables
$border-radii: (
  2,
  10,
  20,
  30,
);

// Borders
@each $name, $hex in $colors {
  .b-#{$name} {
    border: 1px solid $hex;
  }

  .b-right-#{$name} {
    border-right: 1px solid $hex;
  }
}

.b-no {
  border: none;
}

// Border radius
@each $radius in $border-radii {
  .br-#{$radius} {
    border-radius: rem-calc($radius);
  }
}

// Border radius percentage
.br-50p {
  border-radius: 50%;
}
