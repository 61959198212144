.contact {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url("/dist/images/contact-img-small.svg");

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
    margin-bottom: 4px;
  }

  p.font-weight-light {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 2px;
  }

  .form-fields {
    margin-bottom: 3rem;

    label {
      margin-bottom: 10px;
    }
  }
}

@include media-breakpoint-up(md) {
  .contact {
    background-image: url("/dist/images/contact-img-big.svg");
  }
}

@include media-breakpoint-up(lg) {
  .contact {

    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 20px;
      margin-bottom: 6px;
      line-height: 28px;
    }

    p.font-weight-light {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 6px;
    }

    .form-fields {
      width: 65%;
    }

    .form-information {
      width: 35%;
      margin-bottom: 3rem;
    }
  }
}
