.hiw {
  .hiw-accordion {
    a {
      display: flex;
      align-items: center;

      cursor: pointer;

      span {
        font-size: rem-calc(14);
        margin-left: 0.5rem;
      }

      &[aria-expanded="true"] {
        svg {
          circle.bg {
            fill: #1F56CC;
          }

          path.shape {
            fill: #FFFFFF;
          }
        }

        span {
          font-size: rem-calc(16);
          font-weight: 500;
        }
      }
    }
  }

  .image-holder {
    img {
      display: none;

      &.show {
        display: block;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hiw {
    .hiw-accordion {
      a {
        span {
          font-size: rem-calc(16);
          margin-left: 0.5rem;

          &[aria-expanded="true"] {
            svg {
              circle.bg {
                fill: #1F56CC;
              }

              path.shape {
                fill: #FFFFFF;
              }
            }

            span {
              font-size: rem-calc(18);
              font-weight: 500;
            }
          }
        }
      }
    }

    .image-holder {
      img {
        border: 2px solid lightgray;
        box-shadow: 2px 4px 5px lightgray;
      }
    }
  }
}
