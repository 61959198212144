/*
 * Positioning
 */

// Variables
$order-count: 4;
$spacing-count: 4;
$z-index-count: 2;

// Position
.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-fix {
  position: fixed;
}

// Flex
.fl-row {
  flex-direction: row;
}

.fl-col {
  flex-direction: column;
}

.jc-sb {
  justify-content: space-between;
}

.jc-sa {
  justify-content: space-around;
}

.jc-c {
  justify-content: center;
}

.jc-fs {
  justify-content: flex-start;
}

.jc-fe {
  justify-content: flex-end;
}

.ai-fs {
  align-items: flex-start;
}

.ai-c {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}

.ai-s {
  align-items: stretch;
}

.fw-w {
  flex-wrap: wrap;
}

.fw-nw {
  flex-wrap: nowrap;
}

// Order
@for $order from 1 through $order-count {
  .ord-#{$order} {
    order: $order;
  }
}

// Top/Right/Bottom/Left
/*
 * Example:
 * .t-1 => top: 15px;
 * .l-4 => left: 60px;
 */
@for $dir from 1 through 4 {
  @for $count from 0 through $spacing-count {
    .#{nth($shortdirs, $dir)}-#{$count}s {
      #{nth($longdirs, $dir)}: $spacer * $count;
    }
  }

  .#{nth($shortdirs, $dir)}-a {
    #{nth($longdirs, $dir)}: auto;
  }
}

/*
 * Example:
 * .t-10p => top: 10%;
 * .l-33p => left: 33.33333333%;
 */
@each $percentage in $percentages {
  @for $dir from 1 through 4 {
    .#{nth($shortdirs, $dir)}-#{floor($percentage)}p {
      #{nth($longdirs, $dir)}: $percentage * 1%;
    }
  }
}

// Z-index
@for $i from 0 through $z-index-count {
  .z-#{$i} {
    z-index: #{$i};
  }
}

.z-n1 {
  z-index: -1;
}

// Transform
.tr-y-n4s {
  transform: translateY(-($spacer * 4));
}

.tr-y-n6s {
  transform: translateY(-($spacer * 6));
}

//Float
.f-l {
  float: left;
}

.f-r {
  float: right;
}
