.mediators {
  .fader {
    transition: opacity 0.5s linear;
  }

  .fade {
    opacity: 0;
  }
}

@include media-breakpoint-up(lg) {
  .mediators {
    select {
      width: 30%;
    }
  }
}
