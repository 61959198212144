.modal {
  left: 0;
  top: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(100, 100, 100, 0.7);
  font-size: 18px;
  line-height: 26px;

  .modal-content {
    box-shadow: 0 0 5px #101929;
  }

  p {
    margin-bottom: 1rem;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }
}

.close:hover,
.close:focus {
  color: gray;
  text-decoration: none;
  cursor: pointer;
}
