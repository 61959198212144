/*
 * Spacing
 */

// Variables
$margin-count: 20;
$padding-count: 20;

// Margin
@for $count from 0 through $margin-count {
  @for $dir from 1 through 4 {
    .m#{nth($shortdirs, $dir)}-#{$count}s {
      margin-#{nth($longdirs, $dir)}: $spacer * $count;
    }
  }

  .m-#{$count}s {
    margin: $spacer * $count;
  }

  .mx-#{$count}s {
    margin-left: $spacer * $count;
    margin-right: $spacer * $count;
  }

  .my-#{$count}s {
    margin-top: $spacer * $count;
    margin-bottom: $spacer * $count;
  }
}

// Small margin
@each $float in $floats {
  .my-0#{$float * 10}s {
    margin-top: $spacer * $float;
    margin-bottom: $spacer * $float;
  }

  .mx-0#{$float * 10}s {
    margin-left: $spacer * $float;
    margin-right: $spacer * $float;
  }

  .mt-0#{$float * 10}s {
    margin-top: $spacer * $float;
  }

  .mr-0#{$float * 10}s {
    margin-right: $spacer * $float;
  }

  .ml-0#{$float * 10}s {
    margin-left: $spacer * $float;
  }

  .mb-0#{$float * 10}s {
    margin-bottom: $spacer * $float;
  }
}

// Auto
.mr-a {
  margin-right: auto;
}

.ml-a {
  margin-left: auto;
}

.mt-a {
  margin-top: auto;
}

// Negative
.mt-n2s {
  margin-top: $spacer * -2;
}

.mb-n2s {
  margin-bottom: $spacer * -2;
}

.mb-n4s {
  margin-bottom: $spacer * -4;
}

.mb-n6s {
  margin-bottom: $spacer * -6;
}

.mx-n1s {
  margin-left: $spacer * -1;
  margin-right: $spacer * -1;
}

// Padding
@for $count from 0 through $padding-count {
  @for $dir from 1 through 4 {
    .p#{nth($shortdirs, $dir)}-#{$count}s {
      padding-#{nth($longdirs, $dir)}: $spacer * $count;
    }
  }

  .p-#{$count}s {
    padding: $spacer * $count;
  }

  .px-#{$count}s {
    padding-left: $spacer * $count;
    padding-right: $spacer * $count;
  }

  .py-#{$count}s {
    padding-top: $spacer * $count;
    padding-bottom: $spacer * $count;
  }
}

// Small padding
@each $float in $floats {
  .py-0#{$float * 10}s {
    padding-top: $spacer * $float;
    padding-bottom: $spacer * $float;
  }

  .px-0#{$float * 10}s {
    padding-left: $spacer * $float;
    padding-right: $spacer * $float;
  }
}
