.benefits {
  background-color: #F1F2F7;

  .overview {
    background-color: white;
  }
}

@include media-breakpoint-up(sm) {
  .benefits {
    .overview {
      background-color: #F1F2F7;
    }
  }
}

@include media-breakpoint-up(lg) {
  .benefits {
    background-color: white;

    .overview {
      background-color: white;
    }
  }
}
